import { Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';

type FormikSwitchProps = SwitchProps & {
    name: string;
    onChangeCallback?: (value: boolean) => void;
};

const FormikSwitch = ({ ...props }: FormikSwitchProps) => {
    const [field, , helpers] = useField<boolean>(props.name);
    const { value } = field;
    const { setValue } = helpers;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
        if (props.onChangeCallback) props.onChangeCallback(event.target.checked);
    };

    return <Switch {...field} {...props} checked={value} onChange={handleChange} />;
};

export default FormikSwitch;
