import { Box, Typography, Avatar, Theme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tier } from '../../types/model';
import { format } from 'date-fns';
import parseDateFromToday from '../../helpers/date';

type UserAvatarProps = {
    firstName: string;
    dateLastActive?: string;
    tierSubscription?: Tier;
};

const StyledAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.secondary.main,
}));

const UserAvatar = ({ firstName, tierSubscription, dateLastActive }: UserAvatarProps) => {
    const theme = useTheme();
    return (
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <StyledAvatar
                sx={{
                    width: '50px',
                    height: '50px',
                    minWidth: '50px',
                    minHeight: '50px',
                    borderRadius: '100%',
                    backgroundColor: theme.palette.accentMagenta,
                }}>
                {firstName[0].toUpperCase()}
            </StyledAvatar>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                <Typography variant="p1SemiBold">{firstName}</Typography>
                <Typography
                    variant="p2SemiBold"
                    color={!tierSubscription ? theme.palette.black54 : theme.palette.accentMagenta}>
                    {tierSubscription ? `Tier ${tierSubscription}` : 'No subscription'}
                </Typography>
                {dateLastActive ? (
                    <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                        <Typography variant="p2SemiBold">{'Last active: '}</Typography>
                        <Typography variant="p2">{parseDateFromToday(dateLastActive)}</Typography>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export default UserAvatar;
