import { Assistant, AssistantFormData } from './assistant';
import { ModelAttributes, ModelDimensions } from './model';

export type Collection = {
    id: number;
    name: string;
    description: string;
    modelsCount: ModelsCount;
    canBeDeleted?: boolean;
    likes: {
        count: number;
    };
    sortOrder: number;
    thumbnail: string;
    background: string;
    isPublic: boolean;
    showOnLandingPage: boolean;
    banner: {
        thumbnail: string;
        background: string;
    };
    price: number | null;
    dimensions?: ModelDimensions;
    attributes?: ModelAttributes;
    assistant?: Assistant;
};

export type ModelsCount = {
    active: number;
    archived: number;
    total: number;
};

export type CollectionFormData = {
    name: string;
    description: string;
    price: number | null;
    thumbnail: string;
    background: string;
    sortOrder: number;
    isPublic: boolean;
    showOnLandingPage: boolean;
    banner: {
        thumbnail: string;
        background: string;
    };
    dimensions: ModelDimensions;
    attributes: ModelAttributes;
    assistant?: AssistantFormData;
};

export type CollectionApplyDimensions = {
    dimensions: ModelDimensions;
    attributes: ModelAttributes;
};

export const INITIAL_COLLECTION_FORM_DATA: CollectionFormData = {
    name: '',
    description: '',
    price: null,
    sortOrder: 100,
    thumbnail: '',
    background: '',
    isPublic: false,
    showOnLandingPage: false,
    dimensions: {
        height: null,
        breast: null,
        waist: null,
        hips: null,
    },
    attributes: {
        race: null,
        hair: null,
        breast: null,
    },
    banner: {
        thumbnail: '',
        background: '',
    },
};

export type CollectionMutation = {
    collectionFormData: CollectionFormData;
    id: number;
};

export type CollectionApplyDimensionsMutation = {
    collectionFormData: CollectionApplyDimensions;
    id: number;
};
