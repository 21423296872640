import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Snackbar, Alert, AlertColor } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toastAtom } from '../../atoms/toastAtom';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

const DURATION = 4000;

export const Toast: React.FC = () => {
    const [toastState, setToastState] = useAtom(toastAtom);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (toastState) {
            timer = setTimeout(() => {
                setToastState(null);
            }, DURATION);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [toastState, setToastState]);

    if (!toastState) return null;

    const getIcon = (severity: AlertColor) => {
        switch (severity) {
            case 'success':
                return <SuccessIcon fontSize="inherit" />;
            case 'warning':
                return <InfoIcon fontSize="inherit" />;
            case 'error':
                return <ErrorIcon fontSize="inherit" />;
            default:
                return <SuccessIcon fontSize="inherit" />;
        }
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={Boolean(toastState)}
            autoHideDuration={DURATION}
            onClose={() => setToastState(null)}>
            <Alert
                onClose={() => setToastState(null)}
                severity={toastState.severity as AlertColor}
                icon={getIcon(toastState.severity as AlertColor)}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setToastState(null)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }>
                {toastState.message}
            </Alert>
        </Snackbar>
    );
};
