type RecursiveObject = {
    [key: string]:
        | string
        | number
        | boolean
        | null
        | RecursiveObject
        | (string | number | boolean | null)[];
};

export const serializeFilters = <T extends RecursiveObject>(filters: T): string => {
    const keyValuePairs: string[] = [];

    const processObject = (obj: RecursiveObject, prefix = ''): void => {
        Object.entries(obj).forEach(([key, value]) => {
            const prefixedKey = prefix ? `${prefix}.${key}` : key;

            if (value === null) {
                return;
            }

            if (typeof value === 'object' && !Array.isArray(value)) {
                processObject(value, prefixedKey);
            } else if (Array.isArray(value)) {
                const nonNullValues = value.filter((item) => item !== null);
                const arrayValue = nonNullValues.join(',');
                if (arrayValue) {
                    keyValuePairs.push(`filter.${prefixedKey}=${encodeURIComponent(arrayValue)}`);
                }
            } else {
                if (
                    (prefixedKey.includes('sort') ||
                        prefixedKey === 'page' ||
                        prefixedKey === 'pageSize') &&
                    value
                ) {
                    keyValuePairs.push(`${prefixedKey}=${encodeURIComponent(String(value))}`);
                } else if (value) {
                    keyValuePairs.push(
                        `filter.${prefixedKey}=${encodeURIComponent(String(value))}`
                    );
                }
            }
        });
    };

    processObject(filters);
    return keyValuePairs.join('&');
};

const EXCLUDED_KEYS = new Set(['sort', 'page', 'pageSize', 'archived', 'search']);

export const countSelectedFilters = <T extends RecursiveObject>(filters: T): number => {
    let count = 0;

    const countValue = (
        value:
            | string
            | number
            | boolean
            | null
            | RecursiveObject
            | (string | number | boolean | null)[],
        key?: string
    ): number => {
        if (EXCLUDED_KEYS.has(key!)) {
            return 0;
        }

        if (Array.isArray(value)) {
            return value.length > 0 ? 1 : 0;
        } else if (typeof value === 'object' && value !== null) {
            let subCount = 0;
            Object.entries(value).forEach(([subKey, subValue]) => {
                subCount += countValue(subValue, subKey);
            });
            return subCount > 0 ? 1 : 0;
        } else {
            return value && value !== undefined && value !== null ? 1 : 0;
        }
    };

    Object.entries(filters).forEach(([key, value]) => {
        count += countValue(value, key);
    });

    return count;
};
