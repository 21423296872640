import { useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { INITIAL_SIGN_IN_DATA, SignInData } from '../../types/user';
import { SignInValidationSchema } from '../../validation/user';
import FormikTextField from '../../components/Formik/FormikTextField';
import { ReactComponent as VeerlColorLogo } from '../../assets/icons/veerl-icon-color.svg';
import { signIn } from '../../api/user';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { APIError } from '../../axios/axiosVeerlInstance';

const SignInView = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmitHandler = (values: SignInData, helpers: FormikHelpers<SignInData>) => {
        signIn(values.email, values.password)
            .then(async (response) => {
                if (response) {
                    if (!response?.admin) {
                        helpers.setFieldError(
                            'email',
                            "You don't have permission to access this page."
                        );
                        return;
                    }

                    queryClient.setQueryData('user', response);
                    const authToken = response?.authToken;
                    localStorage.setItem('authToken', authToken);

                    navigate('/');
                }
            })
            .catch((error: APIError) => {
                if (error?.message && error.status !== 500) {
                    helpers.setFieldError('email', error.message);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Box
            component="div"
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Box
                component="div"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: 520,
                    gap: 32,
                    padding: '0px 16px 0px 16px',
                }}>
                <VeerlColorLogo width={150} height={150} />
                <Typography variant="h2">Sign in</Typography>
                <Formik
                    initialValues={INITIAL_SIGN_IN_DATA}
                    onSubmit={onSubmitHandler}
                    validationSchema={SignInValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}>
                    {({ submitForm }) => (
                        <Grid
                            container
                            gap="24px"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    submitForm();
                                }
                            }}>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    variant="outlined"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Button
                                    disabled={isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={submitForm}>
                                    {!isLoading ? 'Sign In' : <CircularProgress size={20} />}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SignInView;
