import { Box } from '@mui/material';

type DrawerContentProps = {
    children: React.ReactElement;
};

const DrawerContent = ({ children }: DrawerContentProps) => {
    return (
        <Box
            component="div"
            sx={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                padding: '16px 20px 52px',
            }}>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    width: '100%',
                }}>
                {children}
            </Box>
        </Box>
    );
};

export default DrawerContent;
