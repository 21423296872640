import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import FormikRadio from '../Formik/FormikRadio';
import { useField } from 'formik';

type RadioOption = {
    label: string;
    value: any;
};

type RadioSelectorProps = {
    headline?: string;
    options: RadioOption[];
    name: string;
    uncheckable?: boolean;
    disabled?: boolean;
};

const RadioSelector = ({ headline, options, name, uncheckable, disabled }: RadioSelectorProps) => {
    const [field, , helpers] = useField<any>(name);

    return (
        <FormControlLabel
            control={
                <RadioGroup row>
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option}
                            control={
                                <FormikRadio
                                    name={name}
                                    value={option.value}
                                    disabled={disabled}
                                    onClick={(e: any) => {
                                        if (uncheckable && e.target.value === option.value) {
                                            e.target.checked = false;
                                            helpers.setValue(null);
                                        }
                                    }}
                                />
                            }
                            label={<Typography variant="p2">{option.label}</Typography>}
                        />
                    ))}
                </RadioGroup>
            }
            label={<Typography variant="p2">{headline}</Typography>}
            labelPlacement="start"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: 0,
            }}
        />
    );
};

export default RadioSelector;
