import {
    useTheme,
    Autocomplete,
    TextField,
    AutocompleteProps,
    Typography,
    CircularProgress,
} from '@mui/material';
import { useField } from 'formik';

interface FormikSelectProps
    extends Omit<AutocompleteProps<any, false, false, false>, 'options' | 'renderInput'> {
    name: string;
    label: string;
    valueKey?: string;
    labelKey?: string;
    renderKey?: string;
    isLoading?: boolean;
    items: any;
}

const FormikAutocompleteSelect = ({
    name,
    valueKey,
    labelKey,
    renderKey,
    isLoading,
    items,
    label,
}: FormikSelectProps) => {
    const theme = useTheme();
    const [field, meta, helpers] = useField(name);

    const isError = Boolean(meta.error);

    return (
        <>
            <Autocomplete
                sx={{
                    border: `1px solid ${isError ? theme.palette.alert : theme.palette.borders}`,
                    borderRadius: '4px',
                    background: 'transparent',

                    '& .MuiFilledInput-underline:after': {
                        border: 'none',
                    },
                    '& .MuiFilledInput-underline:before': {
                        borderBottom: 'none',
                    },
                    '& .MuiFilledInput-underline:hover:before': {
                        borderBottom: 'none',
                    },
                    '& .MuiAutocomplete-option': {
                        height: '48px !important',
                        padding: '14px 16px',
                        '&[data-focus="true"]': {
                            backgroundColor: theme.palette.backgroundLighter,
                        },
                        '&[aria-selected="true"]': {
                            backgroundColor: theme.palette.backgroundLighter,
                        },
                    },
                }}
                size="small"
                id={name}
                options={items}
                value={
                    valueKey && field.value && labelKey && items?.length > 0
                        ? items.find((item: any) => item[valueKey] === field.value)
                        : field.value
                }
                getOptionLabel={(option: any) =>
                    labelKey && typeof option[labelKey] === 'string' ? option[labelKey] : option
                }
                getOptionKey={(option: any) => (valueKey ? option[valueKey] : option)}
                onChange={(event, newValue: any) => {
                    helpers.setValue(newValue ? (valueKey ? newValue[valueKey] : newValue) : '');
                }}
                onBlur={() => helpers.setTouched(true)}
                ListboxProps={{
                    sx: {
                        padding: 0,
                    },
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {renderKey ? option[renderKey] : labelKey ? option[labelKey] : option}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        error={isError}
                        name={name}
                        label={label || 'Select options'}
                    />
                )}
            />
            {isError ? (
                <Typography color="error" fontSize="0.75rem" margin="3px 11px 0 11px">
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
};

export default FormikAutocompleteSelect;
