import yup from '../helpers/yup';
import { AssistantValidationSchema } from './assistantSchema';

export const collectionValidationSchema = yup.object({
    name: yup.string().required().max(128),
    description: yup.string().required().max(2048),
    thumbnail: yup.string().required('Collection thumbnail is required.'),
    banner: yup.object({
        thumbnail: yup.string().required('Collection banner thumbnail is required.'),
        background: yup.string().optional(),
    }),
    assistant: AssistantValidationSchema,
});
