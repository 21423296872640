import { AxiosError } from 'axios';
import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { TiersSubscriptionSettings } from '../types/subscription';
import { handleAxiosError } from '../helpers/axios';

export const getTierSubscriptionsPrices = async (): Promise<TiersSubscriptionSettings> => {
    return await axiosVeerlInstance
        .get('/subscription/tiers')
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const updateTierSubscriptionsPrices = async (
    newTiersSubscriptionPrices: TiersSubscriptionSettings
): Promise<TiersSubscriptionSettings> => {
    return await axiosVeerlInstance
        .post('/subscription/tiers', newTiersSubscriptionPrices)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};
