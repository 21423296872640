import {
    Box,
    Button,
    CardActions,
    CardContent,
    CircularProgress,
    Typography,
    useTheme,
} from '@mui/material';

type CustomCardProps = {
    headline: string;
    icon?: any;
    children: React.ReactNode;
    onSubmit?: () => void;
    isLoading?: boolean;
    showFooter?: boolean;
};

const CustomCard = ({
    headline,
    icon,
    children,
    onSubmit,
    isLoading,
    showFooter = true,
}: CustomCardProps) => {
    const theme = useTheme();
    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '8px',
                width: '100%',
                border: `1px solid ${theme.palette.borders}`,
            }}>
            <CardContent
                sx={{
                    padding: !showFooter ? '16px' : '16px 16px 32px 16px',
                    gap: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Box component="div" sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                    {icon}
                    <Typography variant="p1SemiBold">{headline}</Typography>
                </Box>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
                    {children}
                </Box>
            </CardContent>
            {showFooter ? (
                <CardActions
                    sx={{
                        justifyContent: 'flex-end',
                        padding: '16px',
                        backgroundColor: theme.palette.backgroundLighter,
                    }}>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={isLoading}>
                        {isLoading ? <CircularProgress size={20} /> : 'Save'}
                    </Button>
                </CardActions>
            ) : null}
        </Box>
    );
};

export default CustomCard;
