import { Typography, useTheme } from '@mui/material';

type LinkProps = {
    disabled?: boolean;
    label: string;
    onClick?: () => void;
    error?: boolean;
};

const Link = ({ label, disabled, onClick, error }: LinkProps) => {
    const theme = useTheme();

    return (
        <Typography
            variant="p2"
            color={
                disabled
                    ? theme.palette.black54
                    : error
                      ? theme.palette.alert
                      : theme.palette.accentVividBlue
            }
            onClick={disabled ? () => null : onClick}
            sx={{
                textDecoration: 'underline',
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}>
            {label}
        </Typography>
    );
};

export default Link;
