import yup from '../helpers/yup';

const Model3DSchema = yup.object({
    model: yup.string().required('Model is required'),
    assets: yup.array().of(yup.string()).optional(),
});

const Model3DOptionalSchema = yup.object({
    model: yup.string().optional(),
    assets: yup.array().of(yup.string()).optional(),
});

export const DiscountValidationSchema = yup.object({
    active: yup.boolean().required(),
    value: yup.number().when('active', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable().notRequired(),
    }),
    dateStart: yup
        .string()
        .when('active', {
            is: true,
            then: (schema) => schema.required('Start date is required when active.'),
            otherwise: (schema) => schema.nullable().notRequired(),
        })
        .nullable(),
    dateEnd: yup
        .string()
        .when('active', {
            is: true,
            then: (schema) => schema.required('End date is required when active.').nullable(),
            otherwise: (schema) => schema.nullable().notRequired(),
        })
        .test('is-greater', 'End date must be later than start date.', function (value, ctx) {
            const { dateStart, active } = this.parent;

            if (!active || !value || !dateStart) {
                return true;
            }
            const isValid = new Date(value) >= new Date(dateStart);

            if (isValid) return true;
            return ctx.createError({
                message: 'End date must be later than start date.',
            });
        }),
});

export const modelValidationSchema = yup.object().shape({
    id: yup.string(),
    name: yup.string().required(),
    description: yup.array().of(yup.string()).min(1).required(),
    pose: yup.array().of(yup.string()).min(1).required(),
    type: yup.string().oneOf(['static', 'animated', 'video']).required(),
    tier: yup.number().oneOf([1, 2, 3]).required(),
    isPublic: yup.boolean().required(),
    discount: DiscountValidationSchema.optional(),
    datePublication: yup.string().when('isPublic', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable().notRequired(),
    }),
    dimensions: yup
        .object()
        .shape({
            height: yup.number().required(),
            breast: yup.number().required(),
            waist: yup.number().required(),
            hips: yup.number().required(),
        })
        .required(),
    attributes: yup
        .object()
        .shape({
            hair: yup.string().required(),
            race: yup.string().required(),
            breast: yup.string().required(),
        })
        .required(),
    likes: yup.object().shape({
        booster: yup.number().min(0).optional(),
    }),
    highRes: yup.string().when('type', {
        is: 'static' || 'animated',
        then: (schema) => Model3DSchema.required('High resolution model is required.'),
        otherwise: (schema) => Model3DOptionalSchema,
    }),
    lowRes: yup.string().when('type', {
        is: 'static' || 'animated',
        then: (schema) => Model3DSchema.required('High resolution model is required.'),
        otherwise: (schema) => Model3DOptionalSchema,
    }),
    thumbnail: yup.string().required('Thumbnail is required.'),
    video: yup.string().optional(),
    videoAndroid: yup.string().when('type', {
        is: 'video',
        then: (schema) => schema.required('Android video is required.'),
        otherwise: (schema) => schema.optional(),
    }),
    videoIOS: yup.string().when('type', {
        is: 'video',
        then: (schema) => schema.required('iOS video is required.'),
        otherwise: (schema) => schema.optional(),
    }),
    stock: yup.number().integer('Value must be an integer.').optional(),
});
