import { GridColDef } from '@mui/x-data-grid';
import { Box, Theme, Tooltip, Typography } from '@mui/material';

import { User } from '../types/user';
import CustomChip from '../components/CustomChip/CustomChip';

import { ReactComponent as EditIcon } from '../assets/icons/settings.svg';
import parseDateFromToday from '../helpers/date';

type CollectionsColumnsCallbacks = {
    onEdit: (row: User) => void;
    theme: Theme;
};

export const USERS_TABLE_COLUMNS = ({
    theme,
    onEdit,
}: CollectionsColumnsCallbacks): GridColDef<User>[] => [
    {
        field: 'avatar',
        headerName: '',
        sortable: false,
        display: 'flex',
        width: 55,
        renderCell: (params) => (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                    width: '40px',
                    height: '40px',
                    minWidth: '40px',
                    minHeight: '40px',
                    borderRadius: '100%',
                    backgroundColor: theme.palette.accentMagenta,
                }}>
                <Typography variant="p2" color={theme.palette.white}>
                    {params.row.firstName[0]}
                </Typography>
            </Box>
        ),
    },
    {
        field: 'firstName',
        headerName: 'Name',
        width: 220,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.value as string}>
                    <Typography variant="p2SemiBold">{params.value}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 340,
        display: 'flex',
        sortComparator: (v1, v2) => v1[0].localeCompare(v2[0]),
        renderCell: (params) => {
            return (
                <Tooltip title={params.value}>
                    <Typography
                        variant="p2"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            flexGrow: 1,
                        }}>
                        {params.value}
                    </Typography>
                </Tooltip>
            );
        },
    },
    {
        field: 'dateLastActive',
        headerName: 'Last active',
        display: 'flex',
        width: 180,
        valueGetter: (value: string) => {
            const formattedDate = value ? parseDateFromToday(value) : '';
            return formattedDate;
        },
    },
    {
        field: 'superUser',
        headerName: 'Superuser',
        display: 'flex',
        width: 160,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'emailConfirmed',
        headerName: 'Email confirmed',
        display: 'flex',
        width: 160,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'authMethod',
        headerName: 'Auth method',
        display: 'flex',
        width: 160,
        renderCell: (params) => {
            return <CustomChip label={params.value} variant="outlined" />;
        },
    },
    {
        field: 'marketingAgreement',
        headerName: 'Marketing agreement',
        display: 'flex',
        width: 160,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'actions',
        display: 'flex',
        headerName: '',
        headerClassName: 'stickyColumnHeader',
        disableColumnMenu: true,
        sortable: false,
        cellClassName: 'stickyColumnCell',
        width: 100,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%">
                    <Tooltip title="Edit">
                        <EditIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                onEdit(params.row);
                            }}
                        />
                    </Tooltip>
                </Box>
            );
        },
    },
];
