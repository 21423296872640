import { Button, CircularProgress } from '@mui/material';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../../../atoms/toastAtom';
import { useState } from 'react';
import axiosVeerlInstance, { APIError } from '../../../../axios/axiosVeerlInstance';
import { AxiosError } from 'axios';
import { handleAxiosError } from '../../../../helpers/axios';
import { mediaUploadLoadingAtom } from '../../../../atoms/mediaUploadLoadingAtom';

type UploadButtonProps = {
    allowedExtensions?: string[];
    onUpload: (fileUrl: string) => void;
    commonID?: string;
    name?: string;
};

const UploadButton = ({ onUpload, commonID, name, allowedExtensions = [] }: UploadButtonProps) => {
    const setToastState = useSetAtom(toastAtom);
    const setMediaUploadLoadingState = useSetAtom(mediaUploadLoadingAtom);

    const [isUploading, setIsUploading] = useState(false);

    const uploadFile = (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        if (commonID) formData.append('commonID', commonID);

        setIsUploading(true);
        setMediaUploadLoadingState((prev) => [...prev, name || '']);

        axiosVeerlInstance
            .post('asset/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                const { url } = response.data;
                onUpload(url);
            })
            .catch((error: AxiosError) => {
                try {
                    handleAxiosError(error as AxiosError);
                } catch (error) {
                    const apiError = error as APIError;
                    setToastState({
                        severity: 'error',
                        message: apiError.message,
                    });
                }
            })
            .finally(() => {
                setIsUploading(false);
                setMediaUploadLoadingState((prev) => prev.filter((item) => item !== name));
            });
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const uploadedFile = event.target.files[0];
            const fileExtension = '.' + uploadedFile?.name?.split('.').pop()?.toLowerCase() || '';

            if (!allowedExtensions.includes(fileExtension)) {
                setToastState({
                    severity: 'error',
                    message: `Only ${allowedExtensions.join(', ')} files are allowed.`,
                });
                return;
            }

            uploadFile(uploadedFile);
        }
    };

    return (
        <Button
            variant="outlined"
            color="secondary"
            size="small"
            component="label"
            data-name={name}
            sx={{ textTransform: 'none' }}
            disabled={isUploading}>
            {!isUploading ? 'Upload' : <CircularProgress size={20} />}
            <input
                type="file"
                hidden
                onChange={handleUpload}
                accept={allowedExtensions?.length ? allowedExtensions?.join(', ') : '*'}
            />
        </Button>
    );
};

export default UploadButton;
