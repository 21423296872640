import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as XIcon } from '../../assets/icons/x-circle.svg';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 20px 0px',
}));

type DrawerHeaderProps = {
    label: string | React.ReactElement;
    children?: React.ReactElement;
    onClose: () => void;
};

const DrawerHeader = ({ label, children, onClose }: DrawerHeaderProps) => {
    const theme = useTheme();
    return (
        <Container>
            <Grid
                container
                width="100%"
                gap="12px"
                sx={{
                    borderBottom: `1px solid ${theme.palette.borders}`,
                    paddingBottom: '16px',
                }}>
                <Grid item xs={12} width="100%" justifyContent="space-between">
                    <Grid container justifyContent="space-between" alignItems="center">
                        {typeof label === 'string' ? (
                            <Typography variant="p1SemiBold">{label}</Typography>
                        ) : (
                            label
                        )}
                        <IconButton onClick={onClose}>
                            <XIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {children ? (
                    <Grid item xs={12} mt="-8px">
                        {children}
                    </Grid>
                ) : null}
            </Grid>
        </Container>
    );
};

export default DrawerHeader;
