import { Box, Button, Typography } from '@mui/material';
import { useField } from 'formik';

type TierSelectorProps = {
    name: string;
    multiple?: boolean;
    disabled?: boolean;
};

const TierSelector = ({ name, multiple, disabled }: TierSelectorProps) => {
    const [field, , helpers] = useField<number | number[] | null>(name);

    const handleTierSelect = (tier: number) => {
        if (multiple) {
            const newValue = Array.isArray(field.value)
                ? field.value.includes(tier)
                    ? field.value.filter((item) => item !== tier)
                    : [...field.value, tier]
                : [tier];
            helpers.setValue(newValue);
            return;
        }

        helpers.setValue(tier);
    };

    return (
        <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2">Select Tier:</Typography>
            <Box component="div" display="flex" gap="8px">
                {[1, 2, 3].map((tier) => (
                    <Button
                        key={tier}
                        disabled={disabled}
                        size="small"
                        onClick={() => handleTierSelect(tier)}
                        sx={{ textTransform: 'none', borderRadius: 24 }}
                        variant={
                            multiple
                                ? Array.isArray(field.value) && field.value.includes(tier)
                                    ? 'contained'
                                    : 'outlined'
                                : field.value === tier
                                  ? 'contained'
                                  : 'outlined'
                        }>
                        {`Tier ${tier}`}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default TierSelector;
