import { AxiosError } from 'axios';
import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { handleAxiosError } from '../helpers/axios';
import { Assistant, AssistantFormData, AssistantTTSVoices } from '../types/assistant';

export const updateCollectionAssistant = async (
    collectionAssistantFormData: AssistantFormData,
    id: any
): Promise<Assistant | undefined | void> => {
    return await axiosVeerlInstance
        .put<Assistant>(`/collection/` + id + '/assistant', collectionAssistantFormData)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const createCollectionAssistant = async (
    collectionAssistantFormData: AssistantFormData,
    id: any
): Promise<Assistant | undefined | void> => {
    return await axiosVeerlInstance
        .post<Assistant>(`/collection/` + id + '/assistant', collectionAssistantFormData)
        .then((response) => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getAssistantTTSVoices = async (): Promise<AssistantTTSVoices | undefined | void> => {
    return await axiosVeerlInstance
        .get<AssistantTTSVoices>(`/assistant/tts/voices?withSamples=true`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};
