import { TextField, TextFieldProps, styled } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme, error }) => ({
    '& .MuiInputBase-input': {
        backgroundColor: theme.palette.backgroundLighter,
        border: '1px solid ' + (error ? theme.palette.alert : theme.palette.borders),
        borderRadius: '4px',
        color: error ? theme.palette.alert : 'default',

        '&:focus:not(:placeholder-shown)': {
            border: `1px solid ${theme.palette.black84}`,
        },

        // '&:focus': {
        //     border: `1px solid ${theme.palette.black54}`,
        // },
    },
    '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none',
    },
}));

const CustomTextField = (props: TextFieldProps) => {
    return <StyledTextField {...props} variant="filled" />;
};

export default CustomTextField;
