import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import FormikTextField from '../../../components/Formik/FormikTextField';
import FormikSwitch from '../../../components/Formik/FormikSwitch';
import MediaUpload from '../../../components/ModelCreator/MediaUpload/MediaUpload';
import ImageIcon from '@mui/icons-material/Image';
import { useQuery } from 'react-query';
import { getModels } from '../../../api/model';
import FormikAutocompleteSelect from '../../../components/Formik/FormikAutocompleteSelect';
import { getAssistantTTSVoices } from '../../../api/assistant';
import TTSSelect from '../../../components/TTSSelect/TTSSelect';

type AssistantFormProps = {
    collectionID: string;
};

const AssistantForm = ({ collectionID }: AssistantFormProps) => {
    const { data: modelsResponse } = useQuery(
        [
            'models',
            {
                search: '',
                page: 0,
                pageSize: -1,
                archived: 'false',
                collection: {
                    id: [collectionID],
                },
            },
        ],
        () =>
            getModels({
                search: '',
                page: 0,
                pageSize: -1,
                archived: 'false',
                collection: {
                    id: [collectionID],
                },
            })
    );

    const { data: assistantTTSVoices } = useQuery('assistantTTSVoices', getAssistantTTSVoices);

    const models = modelsResponse?.models || [];

    return (
        <Grid container spacing="16px">
            <Grid item xs={12}>
                <FormControlLabel
                    control={<FormikSwitch name="assistant.active" />}
                    label={<Typography variant="p2">Assistant active</Typography>}
                    labelPlacement="start"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        paddingLeft: '4px',
                        justifyContent: 'space-between',
                        marginLeft: 0,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    fullWidth
                    label="Assistant name"
                    name="assistant.name"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{
                        maxLength: 2048,
                    }}
                    label="Assistant description"
                    name="assistant.description"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    fullWidth
                    multiline
                    rows={12}
                    inputProps={{
                        maxLength: 2048,
                    }}
                    label="Prompt"
                    name="assistant.prompt"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{
                        maxLength: 256,
                    }}
                    label="Welcome message"
                    name="assistant.welcomeMessage"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <MediaUpload
                    icon={<ImageIcon />}
                    title="Assistant avatar"
                    availableFormats={['.png', '.jpg', '.jpeg']}
                    name="assistant.avatar"
                    description="Upload .png, .jpg, .jpeg file"
                />
            </Grid>
            <Grid item xs={12}>
                <FormikAutocompleteSelect
                    label="Displayed model"
                    name="assistant.reference.modelID"
                    fullWidth
                    items={models.map((model) => ({
                        id: model.id,
                        name: model.name,
                        render: (
                            <Box component="div" display="flex" alignItems="center" gap="16px">
                                <Box
                                    component="div"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        minWidth: '64px',
                                        minHeight: '64px',
                                        borderRadius: '8px',
                                        backgroundImage: `url(${model.thumbnail})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                    }}
                                />
                                <Typography>{model.name}</Typography>
                            </Box>
                        ),
                    }))}
                    valueKey="id"
                    labelKey="name"
                    renderKey="render"
                />
            </Grid>
            <Grid item xs={12}>
                <TTSSelect voices={assistantTTSVoices || {}} />
            </Grid>
        </Grid>
    );
};

export default AssistantForm;
