import ROUTES from '../router/routes';
import { AppRoute } from '../types/routes';

export const getCurrentSection = (path: string, menuItems: AppRoute[]): AppRoute | undefined => {
    if (path === '/') {
        return menuItems.find((item) => item.path === '/');
    }

    const pathSegments = path.split('/').filter(Boolean);

    const matchingRoute = menuItems.find((route) => {
        const routeSegments = route.path.split('/').filter(Boolean);
        if (routeSegments.length !== pathSegments.length) {
            return false;
        }

        const isMatch = routeSegments.every((segment, index) => {
            return segment === pathSegments[index] || segment.startsWith(':');
        });

        return isMatch;
    });

    if (matchingRoute) {
        return matchingRoute;
    }

    return menuItems
        .filter((item) => path.startsWith(item.path))
        .reduce((current, item) => (item.path.length > current.path.length ? item : current), {
            path: '',
        } as AppRoute);
};

export const findMatchingRoute = (path: string) => {
    let currentPath = path;

    if (currentPath === '/') {
        return '/';
    }

    while (currentPath && currentPath !== '/') {
        const foundRoute = ROUTES.find((route) => route.path === currentPath && route.isMenu);
        if (foundRoute) {
            return foundRoute.path;
        }
        // Move up one level in the path
        const lastSlashIndex = currentPath.lastIndexOf('/');
        currentPath = lastSlashIndex <= 0 ? '/' : currentPath.substring(0, lastSlashIndex);
    }

    return '/';
};
