import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Badge,
    Avatar,
    Box,
    useMediaQuery,
    Menu,
    MenuItem,
} from '@mui/material';

import { ReactComponent as NotificationBellIcon } from '../../assets/icons/notification_bell.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/users_outlined.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/short_arrow.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as VeerlLogo } from '../../assets/logos/veerl_logo.svg';
import { ReactComponent as SignOutIcon } from '../../assets/icons/sign_out.svg';

import { findMatchingRoute, getCurrentSection } from '../../helpers/menu';
import ROUTES from '../../router/routes';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useQueryClient } from 'react-query';
import { signOut } from '../../api/user';

type NavbarProps = {
    openMenu: () => void;
};

const Navbar = ({ openMenu }: NavbarProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    const isBelowMD = useMediaQuery(theme.breakpoints.down('sm'));
    const currentSection = getCurrentSection(location.pathname, ROUTES);
    const goBack = () => {
        // const currentPath = window.location.pathname;
        // const matchingRoutePath = findMatchingRoute(currentPath);
        if (location?.state?.referrer?.length) {
            if (location.state.referrer[location.state.referrer.length - 1] === location.pathname) {
                location.state.referrer.pop();
            }
            navigate(location.state.referrer.pop(), {
                state: {
                    ...location.state,
                    referrer: location.state.referrer,
                },
            });
        } else {
            navigate(-1);
        }
    };

    const isBaseRoute = location.pathname === '/' || !location.pathname.substring(1).includes('/');
    const matchingRoutePath = findMatchingRoute(location.pathname);
    const showBackArrow = !isBaseRoute && matchingRoutePath !== location.pathname;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSignOut = async () => {
        await signOut().then(async () => {
            localStorage.removeItem('authToken');
            Cookies.remove('veerl-auth-token');
            queryClient.setQueryData('user', null);
            navigate('/auth/sign-in');
        });
    };

    return (
        <AppBar
            position="static"
            sx={{
                background: '#ffffff',
                boxShadow: 'none',
                borderBottom: '1px solid #DEE0E3',
            }}>
            <Toolbar>
                {isBelowMD ? (
                    <VeerlLogo />
                ) : (
                    <Box
                        component="div"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        marginLeft="-8px"
                        sx={{
                            '& svg:hover': {
                                cursor: 'pointer',
                            },
                        }}>
                        {(currentSection?.path !== '/' && showBackArrow) ||
                        (location.state?.referrer?.length &&
                            location.state.referrer !== location.pathname) ? (
                            <ArrowBack onClick={goBack} />
                        ) : null}
                        <Typography
                            variant="p2SemiBold"
                            color={theme.palette.black84}
                            component="div"
                            sx={{ flexGrow: 1, cursor: 'default' }}>
                            {currentSection?.title || ''}
                        </Typography>
                    </Box>
                )}
                <Box component="div" sx={{ flexGrow: 1 }} />
                <Box
                    component="div"
                    sx={{ display: 'flex', gap: isBelowMD ? '8px' : '16px', alignItems: 'center' }}>
                    {!isBelowMD && (
                        <Typography
                            variant="p2"
                            component="div"
                            color={theme.palette.black54}
                            sx={{
                                cursor: 'pointer',
                            }}>
                            Website CMS
                        </Typography>
                    )}
                    <IconButton>
                        <Badge variant="dot" color="error">
                            <NotificationBellIcon />
                        </Badge>
                    </IconButton>
                    <IconButton
                        onClick={handleMenu}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                        <Avatar
                            sx={{
                                background: theme.palette.backgroundLighter,
                            }}>
                            <UserIcon color="red" />
                        </Avatar>
                        {!isBelowMD && (
                            <>
                                <Box component="div" width="10px" />
                                <ArrowDown />
                            </>
                        )}
                    </IconButton>
                    {isBelowMD && (
                        <IconButton>
                            <MenuIcon onClick={openMenu} />
                        </IconButton>
                    )}
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={menuOpen}
                        onClose={handleClose}>
                        <MenuItem onClick={onSignOut}>
                            <SignOutIcon style={{ marginRight: '10px' }} />
                            <Typography variant="p2">Sign out</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
