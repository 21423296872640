import React from 'react';
import { useField } from 'formik';
import { Radio, RadioProps } from '@mui/material';

type FormikRadioProps = RadioProps & {
    name: string;
    value: string;
    label?: string;
};

const FormikRadio = ({ name, value, label, ...props }: FormikRadioProps) => {
    const [field, , helpers] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(event.target.value);
    };

    return (
        <Radio
            {...field}
            {...props}
            value={value}
            checked={field.value === value}
            onChange={handleChange}
        />
    );
};

export default FormikRadio;
