import { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Bounds } from '@react-three/drei';
import {
    Box,
    CircularProgress,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Model3D } from '../../types/model';
import ModelMesh from './ModelMesh/ModelMesh';

const ModelViewer = ({ highRes, lowRes }: ModelViewerProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedModelType, setSelectedModelType] = useState<'highRes' | 'lowRes'>(
        highRes ? 'highRes' : 'lowRes'
    );
    const prevHighResRef = useRef<Model3D | undefined>();
    const prevLowResRef = useRef<Model3D | undefined>();

    useEffect(() => {
        const modelChanged = (newModel: Model3D | undefined, prevModel: Model3D | undefined) => {
            return (
                newModel?.model !== prevModel?.model ||
                JSON.stringify(newModel?.assets) !== JSON.stringify(prevModel?.assets)
            );
        };

        const highResChanged = modelChanged(highRes, prevHighResRef.current);
        const lowResChanged = modelChanged(lowRes, prevLowResRef.current);

        if (selectedModelType === 'lowRes' && !lowRes?.model && highRes?.model) {
            setSelectedModelType('highRes');
        } else if (selectedModelType === 'highRes' && !highRes?.model && lowRes?.model) {
            // setSelectedModelType('lowRes');
        } else if (highResChanged && !lowResChanged) {
            setSelectedModelType('highRes');
        } else if (!highResChanged && lowResChanged) {
            // setSelectedModelType('lowRes');
        } else if (highResChanged && lowResChanged) {
            if (selectedModelType === 'lowRes' && lowRes?.model) {
                // setSelectedModelType('lowRes');
            } else if (highRes?.model) {
                setSelectedModelType('highRes');
            }
        }

        prevHighResRef.current = highRes;
        prevLowResRef.current = lowRes;
    }, [highRes, lowRes]);

    return (
        <Grid container width="100%">
            {/* <Grid item xs={12}>
                <ToggleButtonGroup
                    value={selectedModelType}
                    exclusive
                    onChange={(event, newModelType) => {
                        if (newModelType !== null) {
                            setSelectedModelType(newModelType);
                        }
                    }}>
                    <ToggleButton value="highRes" disabled={!highRes?.model}>
                        High-Res
                    </ToggleButton>
                    <ToggleButton value="lowRes" disabled={!lowRes?.model}>
                        Low-Res
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid> */}
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: isSmallScreen ? '266px' : '480px',
                }}>
                <Suspense fallback={<CircularProgress />}>
                    <Canvas resize={{ scroll: false }}>
                        <Bounds fit clip observe>
                            <ModelMesh
                                name={selectedModelType}
                                assets={
                                    selectedModelType === 'highRes'
                                        ? highRes?.assets
                                        : lowRes?.assets
                                }
                                modelUrl={
                                    selectedModelType === 'highRes' ? highRes?.model : lowRes?.model
                                }
                            />
                        </Bounds>
                        <ambientLight intensity={2.25} />
                        <directionalLight position={[0, 10, 0]} intensity={0.5} color="#FFEEBB" />
                        <directionalLight position={[-5, 5, 5]} intensity={0.35} color="#DDEEFF" />
                        <spotLight
                            position={[10, 10, 10]}
                            intensity={0.5}
                            angle={Math.PI / 6}
                            penumbra={1}
                            color="#FFFFFF"
                        />
                    </Canvas>
                </Suspense>
            </Box>
        </Grid>
    );
};

export default ModelViewer;

type ModelViewerProps = {
    highRes?: Model3D;
    lowRes?: Model3D;
};
