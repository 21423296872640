import {
    Box,
    Drawer,
    FormControlLabel,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { MODELS_FILTERS_INITIAL, ModelsFilters } from '../../../types/model';
import { Formik } from 'formik';
import DrawerHeader from '../../Drawer/DrawerHeader';
import DrawerContent from '../../Drawer/DrawerContent';
import DrawerFooter from '../../Drawer/DrawerFooter';
import FormikAutocomplete from '../../Formik/FormikAutocomplete';
import { getModelAttributes, getModelRarities, getPoseTags } from '../../../api/model';
import { useQuery } from 'react-query';
import TierSelector from '../../ModelCreator/TierSelector/TierSelector';
import DimensionRange from '../DimensionRange/DimensionRange';
import DateRange from '../../DateRange/DateRange';
import RadioSelector from '../../RadioSelector/RadioSelector';
import { countSelectedFilters } from '../../../helpers/filters';
import CustomChip from '../../CustomChip/CustomChip';
import FormikSwitch from '../../Formik/FormikSwitch';
import { getCollections } from '../../../api/collection';

type TexturesDrawerProps = {
    filters?: ModelsFilters | null;
    isOpen: boolean;
    onClose: () => void;
    onSave: (filters: ModelsFilters) => void;
};

const ModelsFilterDrawer = ({ isOpen, onClose, onSave, filters }: TexturesDrawerProps) => {
    const theme = useTheme();

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { data: poseTags } = useQuery('poseTags', getPoseTags);
    const { data: modelRarities } = useQuery('modelRarities', getModelRarities);
    const { data: modelAttributes } = useQuery('modelAttributes', getModelAttributes);
    const { data: collectionsResponse } = useQuery('collections', () => getCollections());

    const onSubmitHandler = (values: ModelsFilters) => {
        const currentValues: ModelsFilters = JSON.parse(JSON.stringify(values));

        if (collectionsResponse) {
            currentValues.collection.id = currentValues.collection.id.map((name) => {
                const collection = collectionsResponse.collections.find(
                    (collection) => collection.name === name
                );
                return collection?.id + '' || '';
            });
        }

        onSave(currentValues);
        onClose();
    };

    const parseCollectionsToFilters = (filters?: ModelsFilters) => {
        const currentFilters: ModelsFilters = JSON.parse(JSON.stringify(filters));
        if (collectionsResponse) {
            currentFilters.collection.id = currentFilters.collection.id.map((id) => {
                const collection = collectionsResponse.collections.find(
                    (collection) => collection.id + '' === id + ''
                );
                return collection?.name || '';
            });
        }

        return currentFilters;
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Formik
                initialValues={
                    filters ? parseCollectionsToFilters(filters) : MODELS_FILTERS_INITIAL
                }
                onSubmit={onSubmitHandler}>
                {({ submitForm, values, resetForm }) => {
                    let numberOfSelectedFilters = countSelectedFilters(values);

                    const handleReset = () => {
                        resetForm({
                            values: {
                                ...MODELS_FILTERS_INITIAL,
                                search: filters?.search || '',
                            },
                        });
                    };

                    return (
                        <>
                            <DrawerHeader
                                onClose={onClose}
                                label={
                                    <Box
                                        component="div"
                                        display="flex"
                                        alignItems="center"
                                        gap="8px">
                                        <Typography variant="p1SemiBold">Filters</Typography>
                                        {numberOfSelectedFilters ? (
                                            <>
                                                <CustomChip
                                                    label={numberOfSelectedFilters}
                                                    variant="filled"
                                                />
                                                <Typography
                                                    paddingLeft="16px"
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                        },
                                                    }}
                                                    variant="p2SemiBold"
                                                    color={theme.palette.accentVividBlue}
                                                    onClick={() => handleReset()}>
                                                    Clear all
                                                </Typography>
                                            </>
                                        ) : null}
                                    </Box>
                                }></DrawerHeader>
                            <DrawerContent>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: isMediumScreen ? 'column-reverse' : 'row',
                                        alignItems: isMediumScreen ? 'center' : 'flex-start',
                                    }}>
                                    <Grid
                                        item
                                        xs
                                        style={{
                                            paddingBottom: 0,
                                        }}>
                                        <Grid
                                            container
                                            sx={{
                                                '& > .MuiGrid-item': {
                                                    padding: '16px 0',
                                                    borderBottom:
                                                        '1px solid ' + theme.palette.borders,
                                                },
                                                '& > .MuiGrid-item:first-child': {
                                                    paddingTop: 0,
                                                },
                                                '& > .MuiGrid-item:last-child': {
                                                    borderBottom: 'none',
                                                    paddingBottom: 0,
                                                },
                                            }}>
                                            <Grid item xs={12}>
                                                <Grid container gap="16px">
                                                    <Grid item xs={12}>
                                                        <RadioSelector
                                                            headline="Public"
                                                            name="isPublic"
                                                            uncheckable
                                                            options={[
                                                                {
                                                                    label: 'On',
                                                                    value: 'true',
                                                                },
                                                                {
                                                                    label: 'Off',
                                                                    value: 'false',
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikAutocomplete
                                                            options={poseTags || []}
                                                            creatable={false}
                                                            name="pose"
                                                            label="Pose"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikAutocomplete
                                                    options={
                                                        collectionsResponse?.collections.map(
                                                            (x) => x.name
                                                        ) || []
                                                    }
                                                    creatable={false}
                                                    name="collection.id"
                                                    label="Collection"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikAutocomplete
                                                    options={modelRarities || []}
                                                    creatable={false}
                                                    name="rarity"
                                                    label="Rarity"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RadioSelector
                                                    headline="Type"
                                                    name="type"
                                                    uncheckable
                                                    options={[
                                                        {
                                                            label: 'Static',
                                                            value: 'static',
                                                        },
                                                        {
                                                            label: 'Animated',
                                                            value: 'animated',
                                                        },
                                                        {
                                                            label: 'Video',
                                                            value: 'video',
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TierSelector name="tier" multiple />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing="16px">
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={<FormikSwitch name="isPaid" />}
                                                            label={
                                                                <Typography variant="p2">
                                                                    Is this model paid?
                                                                </Typography>
                                                            }
                                                            labelPlacement="start"
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                marginLeft: 0,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateRange label="Date" name="datePublication" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing="16px">
                                                    <Grid item xs={12}>
                                                        <Grid container spacing="8px">
                                                            <Grid item xs={12}>
                                                                <Typography variant="p1SemiBold">
                                                                    Dimensions
                                                                </Typography>
                                                            </Grid>
                                                            <DimensionRange
                                                                label="Height (cm)"
                                                                name="dimensions.height"
                                                            />
                                                            <DimensionRange
                                                                label="Breasts (cm)"
                                                                name="dimensions.breast"
                                                            />
                                                            <DimensionRange
                                                                label="Waist (cm)"
                                                                name="dimensions.waist"
                                                            />
                                                            <DimensionRange
                                                                label="Hips (cm)"
                                                                name="dimensions.hips"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing="8px">
                                                            <Grid item xs={12}>
                                                                <Typography variant="p1SemiBold">
                                                                    Attributes
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormikAutocomplete
                                                                    creatable={false}
                                                                    options={
                                                                        modelAttributes?.race || []
                                                                    }
                                                                    name="attributes.race"
                                                                    label="Race"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormikAutocomplete
                                                                    creatable={false}
                                                                    options={
                                                                        modelAttributes?.hair || []
                                                                    }
                                                                    name="attributes.hair"
                                                                    label="Hair"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormikAutocomplete
                                                                    creatable={false}
                                                                    options={
                                                                        modelAttributes?.breast ||
                                                                        []
                                                                    }
                                                                    name="attributes.breast"
                                                                    label="Breast"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DrawerContent>
                            <DrawerFooter onSave={submitForm} saveLabel="Filter" />
                        </>
                    );
                }}
            </Formik>
        </Drawer>
    );
};

export default ModelsFilterDrawer;
