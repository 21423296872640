import yup from '../helpers/yup';

export const emailRegexp = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
export const passwordRegexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,64}$/;

export const SignInValidationSchema = yup.object().shape({
    email: yup
        .string()
        .required('This field is required.')
        .email('Provided e-mail is incorrect.')
        .max(128, 'Email cannot be longer than 128 characters.'),
    password: yup
        .string()
        .required('This field is required.')
        .min(8, 'Password must be at least 8 characters long.')
        .max(64, 'Password cannot be more than 64 characters long.')
        .matches(
            passwordRegexp,
            'Password has to contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
});

export const UserAboutValidationSchema = yup.object().shape({
    email: yup
        .string()
        .required('This field is required.')
        .email('Provided e-mail is incorrect.')
        .max(128, 'Email cannot be longer than 128 characters.'),
    firstName: yup
        .string()
        .required('This field is required.')
        .max(64, 'First name cannot be longer than 64 characters.'),
});
