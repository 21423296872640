import axios from 'axios';

export const getConfig = async (reload = false) => {
    let config;

    if (localStorage.getItem('config') && !reload) {
        config = JSON.parse(localStorage.getItem('config') || '{}');
    } else {
        const response = await axios.get('/config.json');
        config = response.data;

        const oldConfig = localStorage.getItem('config') || '{}';
        if (
            oldConfig &&
            JSON.parse(oldConfig).hasOwnProperty('version') &&
            JSON.parse(oldConfig).version !== config.version
        ) {
            localStorage.setItem('config', JSON.stringify(config));
            window.location.reload();
        }

        console.log(`Veerl Admin Panel version: ${config.version}`);
        localStorage.setItem('config', JSON.stringify(config));
    }

    return config;
};

export const getApiPath = async () => {
    const oldConfig = localStorage.getItem('config');
    const config = await getConfig(true);
    if (
        oldConfig &&
        JSON.parse(oldConfig).hasOwnProperty('version') &&
        JSON.parse(oldConfig).version !== config.version
    ) {
        window.location.reload();
    }
    console.log(`Veerl Admin Panel version: ${config.version}`);
};
