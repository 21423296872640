import { Typography, Stack, useTheme, Box, IconButton, Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';

import { useField } from 'formik';

import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import UploadButton from '../ModelUpload/UploadButton/UploadButton';
import axiosVeerlInstance from '../../../axios/axiosVeerlInstance';
import { ResponseType } from 'axios';

type MediaUploadProps = {
    icon: React.ReactElement;
    title: string;
    name: string;
    description: string;
    availableFormats?: string[];
    commonID?: string;
    disabled?: boolean;
};

const MediaUpload = ({
    name,
    availableFormats,
    icon,
    title,
    description,
    commonID,
    disabled,
}: MediaUploadProps) => {
    const theme = useTheme();

    const [field, meta, helpers] = useField<string>(name);

    const fileUrl = field.value;
    const fileExtension = fileUrl ? '.' + fileUrl.split('.').pop()?.toLowerCase() : '';

    const handleUpload = (fileUrl: string) => {
        helpers.setValue(fileUrl);
    };

    const handleDelete = () => {
        helpers.setValue('');
    };

    const handleDownload = () => {
        if (fileUrl) {
            const fileExtension = new URL(fileUrl).pathname.split('.').pop()?.toLowerCase();
            let responseType = 'blob';

            if (fileExtension && ['mp4', 'webm', 'mov'].includes(fileExtension)) {
                responseType = 'arraybuffer';
            }

            axiosVeerlInstance
                .get(fileUrl + '?not-cache', {
                    responseType: responseType as ResponseType,
                })
                .then((response) => {
                    if (response?.data) {
                        const blob = new Blob([response.data], {
                            type: response.headers['content-type'],
                        });
                        const fileName = new URL(fileUrl).pathname.split('/').pop() || 'download';
                        saveAs(blob, fileName);
                    }
                })
                .catch((error) => {
                    console.error('Error downloading file:', error);
                });
        }
    };

    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '8px',
            }}>
            <Stack direction="row" alignItems="center" spacing={2}>
                {fileUrl ? (
                    fileExtension === '.mp4' ||
                    fileExtension === '.mov' ||
                    fileExtension === '.webm' ||
                    fileExtension === '.avi' ||
                    fileExtension === '.mkv' ? (
                        <Box
                            component="video"
                            src={fileUrl}
                            sx={{
                                width: 100,
                                height: 80,
                                minWidth: 100,
                                minHeight: 80,
                                borderRadius: '4px',
                                objectFit: 'cover',
                            }}
                            controls={false}
                        />
                    ) : (
                        <Box
                            component="img"
                            src={fileUrl}
                            sx={{
                                width: 100,
                                height: 80,
                                minWidth: 100,
                                minHeight: 80,
                                borderRadius: '4px',
                                objectFit: 'cover',
                            }}
                        />
                    )
                ) : (
                    <Box
                        component="div"
                        sx={{
                            width: 100,
                            height: 80,
                            minWidth: 100,
                            minHeight: 80,
                            borderRadius: '4px',
                            display: 'flex',
                            backgroundColor: theme.palette.backgroundLighter,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {icon}
                    </Box>
                )}
                <Box component="div" flexDirection="column" display="flex">
                    <Typography
                        variant="p1"
                        sx={{
                            wordBreak: 'break-word',
                        }}
                        color={Boolean(meta.error) ? theme.palette.alert : 'default'}>
                        {fileUrl ? new URL(fileUrl).pathname.split('/').pop() : title}
                    </Typography>
                    {!fileUrl ? (
                        <Typography
                            marginTop="2px"
                            variant="p2"
                            color={
                                Boolean(meta.error) ? theme.palette.alert : theme.palette.black54
                            }>
                            {description}
                        </Typography>
                    ) : null}
                    {Boolean(meta.error) ? (
                        <Box component="div">
                            <Typography color="error" fontSize="0.75rem" margin="4px 0px">
                                ({meta.error})
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </Stack>
            {disabled ? null : fileUrl ? (
                <Box component="div" sx={{ display: 'flex' }}>
                    <Tooltip title="Delete asset">
                        <IconButton onClick={handleDelete}>
                            <TrashIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download asset">
                        <IconButton onClick={handleDownload} aria-label="download">
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ) : (
                <UploadButton
                    name={name}
                    commonID={commonID}
                    onUpload={handleUpload}
                    allowedExtensions={availableFormats}
                />
            )}
        </Box>
    );
};

export default MediaUpload;
