import { useEffect, useState } from 'react';
import { Drawer, Typography, Box, List, ListItem, useTheme, Badge } from '@mui/material';
import UploadedAsset from '../UploadedAsset/UploadedAsset';

import UploadButton from '../UploadButton/UploadButton';
import DrawerHeader from '../../../Drawer/DrawerHeader';
import DrawerFooter from '../../../Drawer/DrawerFooter';
import DrawerContent from '../../../Drawer/DrawerContent';
import { useField } from 'formik';

type TexturesDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    assets: string[];
    onSave: (assets: string[]) => void;
    type: string;
    commonID: string;
    disabled?: boolean;
};

const TexturesDrawer = ({
    isOpen,
    onClose,
    assets,
    onSave,
    type,
    commonID,
    disabled,
}: TexturesDrawerProps) => {
    const theme = useTheme();

    const [assetsErrorsField] = useField<string[]>(`${type}.assetsErrors`);

    const [currentAssets, setCurrentAssets] = useState<string[]>([]);

    useEffect(() => {
        if (isOpen) {
            setCurrentAssets(assets);
        }
    }, [isOpen]);

    const handleDelete = (fileUrl: string) => {
        setCurrentAssets((prev) => prev.filter((f: string) => f !== fileUrl));
    };

    const handleUpload = (fileUrl: string) => {
        setCurrentAssets((prev) => [...prev, fileUrl]);
    };

    const handleSave = () => {
        onSave(currentAssets);
    };

    return (
        <Drawer anchor="left" open={isOpen} onClose={onClose}>
            <DrawerHeader
                onClose={onClose}
                label={
                    <Box component="div" display="flex" alignItems="center" gap="16px">
                        <Typography variant="p1SemiBold">
                            {type === 'highRes' ? 'High-Res' : 'Low-Res'} model assets
                        </Typography>
                        <Badge badgeContent={currentAssets.length} />
                    </Box>
                }>
                <>
                    {!disabled ? (
                        <UploadButton
                            commonID={commonID + '-' + type}
                            onUpload={handleUpload}
                            allowedExtensions={['.jpg', '.jpeg', '.bmp', '.mtl']}
                        />
                    ) : null}
                </>
            </DrawerHeader>
            <DrawerContent>
                {currentAssets?.length ? (
                    <List
                        sx={{
                            width: '100%',
                            paddingTop: '0',
                        }}>
                        {currentAssets.map((fileUrl: string) => (
                            <ListItem
                                key={fileUrl}
                                sx={{
                                    width: '100%',
                                    padding: '16px 0',
                                    borderBottom: `1px solid ${theme.palette.borders}`,
                                    '&:first-child': {
                                        paddingTop: '0',
                                    },
                                }}>
                                <UploadedAsset
                                    disabled={disabled}
                                    fileUrl={fileUrl}
                                    handleDelete={() => handleDelete(fileUrl)}
                                    error={
                                        assetsErrorsField.value?.includes(fileUrl)
                                            ? 'No material found in 3D file for this texture.'
                                            : ''
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography
                        variant="p2"
                        color={theme.palette.black54}
                        mt="16px"
                        justifySelf="center">
                        No assets uploaded.
                    </Typography>
                )}
            </DrawerContent>
            <DrawerFooter
                anchor="left"
                saveLabel={disabled ? 'Close' : 'Save'}
                onSave={disabled ? onClose : handleSave}
            />
        </Drawer>
    );
};

export default TexturesDrawer;
