import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { useAxiosInterceptor } from './axios/axiosVeerlInstance';
import RouteWrapper from './views/RouteWrapper';

const App = () => {
    useAxiosInterceptor();
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <RouteWrapper />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
