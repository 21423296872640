import { CircularProgress, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type CustomDialogProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    content: React.ReactElement | string;
    confirmLabel?: string;
    variant?: 'primary' | 'danger';
    isLoading?: boolean;
};

const CustomDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    content,
    isLoading,
    variant = 'primary',
    confirmLabel = 'Yes',
}: CustomDialogProps) => {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading} variant="outlined" color="secondary">
                    No
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    sx={
                        variant === 'danger'
                            ? {
                                  backgroundColor: theme.palette.accentMagenta,
                                  color: theme.palette.white,
                                  gap: '8px',

                                  '&:hover, &:active, &:focus, &:disabled': {
                                      backgroundColor: theme.palette.accentMagenta,
                                  },
                              }
                            : {}
                    }>
                    {isLoading ? (
                        <>
                            <CircularProgress size={16} sx={{ color: 'white' }} />
                        </>
                    ) : (
                        confirmLabel
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
