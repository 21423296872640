import { AxiosError } from 'axios';
import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { serializeFilters } from '../helpers/filters';
import { Payment } from '../types/payments';
import { BaseFilters } from '../types/table';
import { handleAxiosError } from '../helpers/axios';

export type GetPaymentHistoryResponse = {
    history: Payment[];
    totalResults: number;
};

export const getPaymentHistory = async (
    baseFilters?: BaseFilters
): Promise<GetPaymentHistoryResponse> => {
    const queryParams = new URLSearchParams(baseFilters ? serializeFilters(baseFilters) : '');
    return await axiosVeerlInstance
        .get(`/payment/history?${queryParams.toString()}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};
