import { styled } from '@mui/material/styles';

import { Typography, Box, IconButton, Grid, Theme, Tooltip, useTheme } from '@mui/material';

import { ReactComponent as ModelsIcon } from '../../../../assets/icons/models.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';

type FileNameContainerProps = {
    error: string | undefined;
    theme: Theme;
};

const StyledModelsIcon = styled(ModelsIcon)`
    width: 16px;
    min-width: 16px;
    height: auto;
`;

const FileNameContainer = styled(Box)(({ theme, error }: FileNameContainerProps) => ({
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.lightBlue,
    borderRadius: '4px',
    border: error ? `1px solid ${theme.palette.alert}` : `none`,
    padding: '0 12px',
    height: '48px',
    width: '100%',

    '& path': {
        stroke: error ? theme.palette.alert : theme.palette.primary.main,
    },
}));

type UploadedAssetProps = {
    fileUrl: string;
    handleDelete: () => void;
    error?: string;
    disabled?: boolean;
};

const UploadedAsset = ({ fileUrl, handleDelete, error, disabled }: UploadedAssetProps) => {
    const theme = useTheme();

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = new URL(fileUrl).pathname.split('/').pop() || 'download.usdz';
        link.setAttribute('download', fileName.endsWith('.usdz') ? fileName : `${fileName}.usdz`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FileNameContainer error={error} theme={theme}>
                    <StyledModelsIcon />
                    <Tooltip title={new URL(fileUrl).pathname.split('/').pop()}>
                        <Typography
                            variant="p1"
                            sx={{
                                flexGrow: 1,
                                ml: '8px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 'calc(100% - 16px)',
                                color: error ? 'error' : 'default',
                            }}
                            color={error ? 'error' : 'default'}>
                            {new URL(fileUrl).pathname.split('/').pop()}
                        </Typography>
                    </Tooltip>
                    {!disabled ? (
                        <>
                            <Tooltip title="Delete asset">
                                <IconButton onClick={handleDelete} aria-label="delete">
                                    <TrashIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download asset">
                                <IconButton onClick={handleDownload} aria-label="download">
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : null}
                </FileNameContainer>
            </Grid>
            {error ? (
                <Grid
                    item
                    xs={12}
                    sx={{
                        padding: '2px 12px',
                    }}>
                    <Typography variant="p2" color="error">
                        {error}
                    </Typography>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default UploadedAsset;
