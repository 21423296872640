import { AxiosError } from 'axios';
import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { GetUsersReponse, User, UserPersonalData } from '../types/user';
import { handleAxiosError } from '../helpers/axios';
import { serializeFilters } from '../helpers/filters';
import { BaseFilters } from '../types/table';
import { AddAssetFormData, UserAssets } from '../types/assets';
import { AddSubscriptionFormData } from '../types/subscription';

export const signIn = async (email: string, password: string) => {
    return await axiosVeerlInstance
        .post<User>(`/auth/signin`, { email, password })
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const signOut = async () => {
    return await axiosVeerlInstance
        .post(`/auth/signout`)
        .then(async (response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getUser = async () => {
    return await axiosVeerlInstance
        .get<User>(`/user/data`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getSpecificUser = async (uid?: string) => {
    return await axiosVeerlInstance
        .get<User>(`/user/${uid}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getUserAssets = async (uid?: string, baseFilters?: BaseFilters) => {
    const queryParams = new URLSearchParams(baseFilters ? serializeFilters(baseFilters) : '');

    return await axiosVeerlInstance
        .get<UserAssets>(`/user/${uid}/assets?${queryParams.toString()}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const addUserAsset = async (uid: string, addAssetFormData: AddAssetFormData) => {
    return await axiosVeerlInstance
        .post(`/user/${uid}/asset`, addAssetFormData)
        .then((response) => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const deleteUserAsset = async (uid: string, assetID: string) => {
    return await axiosVeerlInstance
        .delete(`/user/${uid}/asset/${assetID}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const addUserSubscription = async (
    uid: string,
    addSubscriptionFormData: AddSubscriptionFormData
) => {
    return await axiosVeerlInstance
        .post(`/user/${uid}/asset/subscription`, addSubscriptionFormData)
        .then((response) => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const deleteUserSubscription = async (uid: string, subscriptionID: string) => {
    return await axiosVeerlInstance
        .delete(`/user/${uid}/asset/subscription/${subscriptionID}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const cancelUserSubscription = async (uid: string, subscriptionID: string) => {
    return await axiosVeerlInstance
        .post(`/user/${uid}/asset/subscription/${subscriptionID}/cancel`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const updateUserPersonalData = async (userID: string, data: UserPersonalData) => {
    return await axiosVeerlInstance
        .put(`/user/` + userID, data)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const deleteUser = async (userID: string) => {
    return await axiosVeerlInstance
        .delete(`/user/` + userID)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getUsers = async (filters: BaseFilters) => {
    const queryParams = serializeFilters(filters);

    return await axiosVeerlInstance
        .get<GetUsersReponse>(`/users?${queryParams}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};
