import CustomCard from '../../components/CustomCard/CustomCard';
import { ReactComponent as TiersIcon } from '../../assets/icons/mint.svg';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { TiersSubscriptionSettings } from '../../types/subscription';
import { TierSubscriptionsValidationSchema } from '../../validation/subscriptions';
import FormikTextField from '../../components/Formik/FormikTextField';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getTierSubscriptionsPrices, updateTierSubscriptionsPrices } from '../../api/subscriptions';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { APIError } from '../../axios/axiosVeerlInstance';
import MediaUpload from '../../components/ModelCreator/MediaUpload/MediaUpload';
import ImageIcon from '@mui/icons-material/Image';

const TiersSubscriptions = () => {
    const queryClient = useQueryClient();
    const setToastState = useSetAtom(toastAtom);

    const { data: tiersSubscriptionPrices, isLoading } = useQuery(
        'tierSubscriptionPrices',
        getTierSubscriptionsPrices,
        {
            onSuccess(data) {
                if (!data.tier1) {
                    data.tier1 = {
                        price: null,
                        thumbnail: '',
                    };
                }

                if (!data.tier2) {
                    data.tier2 = {
                        price: null,
                        thumbnail: '',
                    };
                }

                if (!data.tier3) {
                    data.tier3 = {
                        price: null,
                        thumbnail: '',
                    };
                }

                return data;
            },
        }
    );

    const { mutateAsync: updateTiersPrices, isLoading: isUpdatingPrices } = useMutation(
        'updateTiersPrices',
        updateTierSubscriptionsPrices,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('tierSubscriptionPrices');
                setToastState({
                    message: 'Tier subscriptions prices updated successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const onTiersSubscriptionsSubmit = async (values: TiersSubscriptionSettings) => {
        try {
            if (!values.tier1.price) values.tier1.price = null;
            if (!values.tier2.price) values.tier2.price = null;
            if (!values.tier3.price) values.tier3.price = null;
            await updateTiersPrices(values);
        } catch (err) {}
    };

    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: isLoading || !tiersSubscriptionPrices ? 'center' : 'flex-start',
                padding: '64px 16px',
                marginBottom: '16px',
            }}>
            {isLoading || !tiersSubscriptionPrices ? (
                <CircularProgress size={45} />
            ) : (
                <Box
                    component="div"
                    maxWidth="920px"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                    <Formik
                        initialValues={tiersSubscriptionPrices}
                        onSubmit={onTiersSubscriptionsSubmit}
                        validationSchema={TierSubscriptionsValidationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}>
                        {({ submitForm }) => (
                            <CustomCard
                                headline="Tier Subscriptions"
                                icon={<TiersIcon />}
                                isLoading={isUpdatingPrices}
                                onSubmit={submitForm}>
                                <Grid container gap="24px">
                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        alignItems="center"
                                        gap="12px">
                                        <Grid container gap="12px">
                                            <Grid item xs={12}>
                                                <Typography variant="p2SemiBold">Tier 1</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikTextField
                                                    fullWidth
                                                    label="Subscription price"
                                                    name="tier1.price"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MediaUpload
                                                    icon={<ImageIcon />}
                                                    title="Tier 1 thumbnail"
                                                    availableFormats={['.png']}
                                                    name="tier1.thumbnail"
                                                    description="Upload .png file"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        alignItems="center"
                                        gap="12px">
                                        <Grid container gap="12px">
                                            <Grid item xs={12}>
                                                <Typography variant="p2SemiBold">Tier 2</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikTextField
                                                    fullWidth
                                                    label="Subscription price"
                                                    name="tier2.price"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MediaUpload
                                                    icon={<ImageIcon />}
                                                    title="Tier 2 thumbnail"
                                                    availableFormats={['.png']}
                                                    name="tier2.thumbnail"
                                                    description="Upload .png file"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        alignItems="center"
                                        gap="12px">
                                        <Grid container gap="12px">
                                            <Grid item xs={12}>
                                                <Typography variant="p2SemiBold">Tier 3</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikTextField
                                                    fullWidth
                                                    label="Subscription price"
                                                    name="tier3.price"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MediaUpload
                                                    icon={<ImageIcon />}
                                                    title="Tier 3 thumbnail"
                                                    availableFormats={['.png']}
                                                    name="tier3.thumbnail"
                                                    description="Upload .png file"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomCard>
                        )}
                    </Formik>
                </Box>
            )}
        </Box>
    );
};

export default TiersSubscriptions;
