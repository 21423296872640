import {
    Button,
    FormControlLabel,
    Typography,
    Box,
    Grid,
    useTheme,
    useMediaQuery,
    CircularProgress,
} from '@mui/material';
import MediaUpload from '../../components/ModelCreator/MediaUpload/MediaUpload';
import ImageIcon from '@mui/icons-material/Image';
import VideoIcon from '@mui/icons-material/PlayCircleOutline';
import ModelUploadBox from '../../components/ModelCreator/ModelUploadBox';
import TierSelector from '../../components/ModelCreator/TierSelector/TierSelector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    createModel,
    getDescriptionTags,
    getModel,
    getModelAttributes,
    getModelID,
    getModelRarities,
    getPoseTags,
    updateModel,
} from '../../api/model';
import { Formik, useFormikContext } from 'formik';
import { INITAL_MODEL_DATA, ModelFormData } from '../../types/model';
import FormikSwitch from '../../components/Formik/FormikSwitch';
import FormikTextField from '../../components/Formik/FormikTextField';
import SelectTime from '../../components/SelectTime/SelectTime';
import FormikAutocomplete from '../../components/Formik/FormikAutocomplete';
import FormikSelect from '../../components/Formik/FormikSelect';
import { modelValidationSchema } from '../../validation/modelSchema';
import ModelUpload from '../../components/ModelCreator/ModelUpload/ModelUpload';
import { customValidate } from '../../helpers/validation';
import ModelPrice from '../../components/ModelCreator/ModelPrice/ModelPrice';
import { useAtomValue, useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import ModelViewer from '../../components/ModelViewer/ModelViewer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RadioSelector from '../../components/RadioSelector/RadioSelector';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { getCollections } from '../../api/collection';
import ModelFree from '../../components/ModelCreator/ModelFree/ModelFree';
import { APIError } from '../../axios/axiosVeerlInstance';
import { formatISO, parseISO } from 'date-fns';
import { mediaUploadLoadingAtom } from '../../atoms/mediaUploadLoadingAtom';
import ModelCollectionPicker from '../../components/ModelCreator/ModelCollectionPicker/ModelCollectionPicker';

const ModelCreator = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    const { collection } = location.state || {};
    const { mid } = useParams();
    const isEditMode = !!mid;

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const mediaUploadLoading = useAtomValue(mediaUploadLoadingAtom);
    const setToastState = useSetAtom(toastAtom);

    const [initialFormData, setInitialFormData] = useState<ModelFormData>({
        ...INITAL_MODEL_DATA,
        dimensions: {
            height: collection?.dimensions?.height || null,
            breast: collection?.dimensions?.breast || null,
            waist: collection?.dimensions?.waist || null,
            hips: collection?.dimensions?.hips || null,
        },
        attributes: {
            race: collection?.attributes?.race || '',
            hair: collection?.attributes?.hair || '',
            breast: collection?.attributes?.breast || '',
        },
        collectionsIds: collection ? [collection.name] : [],
    });

    const { isFetched: isFetchedModel } = useQuery(['model', mid], () => getModel(mid), {
        enabled: !!isEditMode,
        onSuccess: (data) => {
            const currentFormData = data as ModelFormData;
            if (currentFormData) {
                currentFormData.collectionsIds = currentFormData?.collections?.map(
                    (collection) => collection.name
                );
                currentFormData.background = currentFormData.background || '';

                setInitialFormData(currentFormData as ModelFormData);
            }
        },
        onError: (error: AxiosError) => {
            if (error.response?.status === 404) {
                navigate('/models', {
                    state: location.state,
                });
            }
        },
    });
    const { data: modelAttributes } = useQuery('modelAttributes', getModelAttributes);
    const { data: modelRarities } = useQuery('modelRarities', getModelRarities);
    const { data: descriptionTags } = useQuery('descriptionTags', getDescriptionTags);
    const { data: collectionsResponse } = useQuery('collections', () => getCollections());
    const { data: poseTags } = useQuery('poseTags', getPoseTags);

    const { data: modelID } = useQuery('modelID', getModelID, {
        enabled: !isEditMode,
        cacheTime: 0,
        staleTime: 0,
    });

    const isPreviewMode = isEditMode && initialFormData.archived;

    const { mutateAsync: mutateCreateModel, isLoading: isCreatingModel } = useMutation(
        'createModel',
        createModel,
        {
            onSuccess: (data) => {
                navigate('/models', {
                    state: location.state,
                });
                setToastState({
                    message: 'Model created successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const { mutateAsync: mutateUpdateModel, isLoading: isUpdatingModel } = useMutation(
        'updateModel',
        updateModel,
        {
            onSuccess: (data) => {
                if (location?.state?.modelsFilters) {
                    queryClient.invalidateQueries(['models', location?.state?.modelsFilters]);
                } else {
                    queryClient.invalidateQueries('models');
                }

                navigate('/models', {
                    state: location.state,
                });
                setToastState({
                    message: 'Model updated successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const onSubmitHandler = async (values: ModelFormData) => {
        if (isPreviewMode) {
            navigate('/models', {
                state: location.state,
            });
            return;
        }

        const currentValues: ModelFormData = JSON.parse(JSON.stringify(values));
        if (!currentValues?.price && currentValues?.price !== 0) currentValues.price = null;
        if (!currentValues?.likes?.booster && currentValues?.likes?.booster !== 0)
            currentValues.likes.booster = 0;
        if (!currentValues?.sortOrder && currentValues?.sortOrder !== 0)
            currentValues.sortOrder = 100;

        if (currentValues?.stock === '') {
            currentValues.stock = null;
        }

        currentValues.collectionsIds = currentValues?.collectionsIds?.map((collectionName) => {
            const collection = collectionsResponse?.collections.find(
                (collection) => collection.name === collectionName
            );
            return collection?.id + '' || '';
        });
        if (isEditMode) {
            try {
                await mutateUpdateModel(currentValues);
            } catch (err) {}
        } else {
            if (modelID?.id) {
                currentValues.id = modelID.id;
                try {
                    await mutateCreateModel(currentValues);
                } catch (err) {}
            }
        }
    };

    if (isEditMode && !initialFormData?.id) {
        return (
            <Box
                component="div"
                flexGrow="1"
                alignItems="center"
                justifyContent="center"
                width="100%"
                display="flex">
                <CircularProgress size={45} />
            </Box>
        );
    }

    return (
        <Formik
            initialValues={initialFormData}
            onSubmit={onSubmitHandler}
            validate={(values) => customValidate(values, modelValidationSchema)}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={modelValidationSchema}>
            {({ submitForm, values, setFieldValue, setFieldError }) => {
                return (
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: isMediumScreen ? 'column-reverse' : 'row',
                            alignItems: isMediumScreen ? 'center' : 'flex-start',
                            flexWrap: 'nowrap',
                        }}>
                        <Grid item>
                            <Box
                                component="div"
                                sx={{
                                    borderRight: `1px solid ${theme.palette.borders}`,
                                    width: '430px',
                                    padding: '24px 20px',
                                }}>
                                <Grid
                                    item
                                    xs
                                    style={{
                                        paddingBottom: 0,
                                    }}>
                                    <Grid
                                        container
                                        sx={{
                                            '& > .MuiGrid-item': {
                                                padding: '16px 0',
                                                borderBottom: '1px solid ' + theme.palette.borders,
                                            },
                                            '& > .MuiGrid-item:first-child': {
                                                paddingTop: 0,
                                            },
                                            '& > .MuiGrid-item:last-child': {
                                                borderBottom: 'none',
                                                paddingBottom: 0,
                                            },
                                        }}>
                                        <Grid item xs={12}>
                                            <Typography variant="p2">
                                                Model No:{' '}
                                                <Typography
                                                    variant="p2"
                                                    color={theme.palette.black54}>
                                                    {isEditMode ? initialFormData.id : modelID?.id}
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ModelUpload
                                                disabled={isPreviewMode}
                                                commonID={
                                                    isEditMode
                                                        ? initialFormData.id
                                                        : modelID?.id || ''
                                                }
                                                modelType="highRes"
                                                name="highRes"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ModelUpload
                                                disabled={isPreviewMode}
                                                commonID={
                                                    isEditMode
                                                        ? initialFormData.id
                                                        : modelID?.id || ''
                                                }
                                                modelType="lowRes"
                                                name="lowRes"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing="16px">
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        fullWidth
                                                        disabled={isPreviewMode}
                                                        label="Name"
                                                        name="name"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ModelCollectionPicker
                                                        collections={
                                                            collectionsResponse?.collections || []
                                                        }
                                                        isPreviewMode={isPreviewMode}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikAutocomplete
                                                        options={descriptionTags || []}
                                                        name="description"
                                                        label="Description"
                                                        disabled={isPreviewMode}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikSelect
                                                        variant="filled"
                                                        label="Rarity"
                                                        name="rarity"
                                                        fullWidth
                                                        items={modelRarities}
                                                        disabled={isPreviewMode}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikAutocomplete
                                                        options={poseTags || []}
                                                        name="pose"
                                                        label="Pose"
                                                        disabled={isPreviewMode}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        fullWidth
                                                        disabled={isPreviewMode}
                                                        type="number"
                                                        label="Likes booster"
                                                        name="likes.booster"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        fullWidth
                                                        disabled={isPreviewMode}
                                                        type="number"
                                                        label="Sort order"
                                                        name="sortOrder"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioSelector
                                                name="type"
                                                headline="Type"
                                                disabled={isPreviewMode}
                                                options={[
                                                    {
                                                        label: 'Static',
                                                        value: 'static',
                                                    },
                                                    {
                                                        label: 'Animated',
                                                        value: 'animated',
                                                    },
                                                    {
                                                        label: 'Video',
                                                        value: 'video',
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                        <ModelFree isPreviewMode={isPreviewMode} />
                                        <Grid item xs={12}>
                                            <ModelPrice
                                                disabled={
                                                    isPreviewMode ||
                                                    values.isFreeForUsers ||
                                                    values.isFreeForGuests
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing="16px">
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        disabled={isPreviewMode}
                                                        commonID={
                                                            isEditMode
                                                                ? initialFormData.id
                                                                : modelID?.id || ''
                                                        }
                                                        icon={<ImageIcon />}
                                                        title="Model miniature"
                                                        availableFormats={['.png', '.jpg', '.jpeg']}
                                                        name="thumbnail"
                                                        description="Upload .png, .jpg, .jpeg file"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        disabled={isPreviewMode}
                                                        commonID={
                                                            isEditMode
                                                                ? initialFormData.id
                                                                : modelID?.id || ''
                                                        }
                                                        icon={<ImageIcon />}
                                                        title="Model background"
                                                        availableFormats={['.png', '.jpg', '.jpeg']}
                                                        name="background"
                                                        description="Upload .png, .jpg, .jpeg file"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        disabled={isPreviewMode}
                                                        commonID={
                                                            isEditMode
                                                                ? initialFormData.id
                                                                : modelID?.id || ''
                                                        }
                                                        icon={<VideoIcon />}
                                                        title="Video"
                                                        name="video"
                                                        availableFormats={[
                                                            '.mp4',
                                                            '.mov',
                                                            '.webm',
                                                            '.avi',
                                                            '.mkv',
                                                        ]}
                                                        description="Upload .mp4, .mov, .webm, file"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        disabled={isPreviewMode}
                                                        commonID={
                                                            isEditMode
                                                                ? initialFormData.id
                                                                : modelID?.id || ''
                                                        }
                                                        icon={<VideoIcon />}
                                                        title="Video AR (Android)"
                                                        name="videoAndroid"
                                                        availableFormats={['.mp4', '.mov', '.webm']}
                                                        description="Upload .mp4, .mov, .webm, file"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        disabled={isPreviewMode}
                                                        commonID={
                                                            isEditMode
                                                                ? initialFormData.id
                                                                : modelID?.id || ''
                                                        }
                                                        icon={<VideoIcon />}
                                                        title="Video AR (iOS)"
                                                        name="videoIOS"
                                                        availableFormats={['.mov', '.hevc']}
                                                        description="Upload .mov file"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TierSelector name="tier" disabled={isPreviewMode} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing="16px">
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <FormikSwitch
                                                                name="isPublic"
                                                                disabled={isPreviewMode}
                                                                onChangeCallback={(value) => {
                                                                    if (!value) {
                                                                        setFieldValue(
                                                                            'datePublication',
                                                                            ''
                                                                        );
                                                                        setFieldError(
                                                                            'datePublication',
                                                                            ''
                                                                        );
                                                                    } else {
                                                                        setFieldValue(
                                                                            'datePublication',
                                                                            formatISO(
                                                                                parseISO(
                                                                                    new Date().toISOString()
                                                                                ),
                                                                                {
                                                                                    representation:
                                                                                        'complete',
                                                                                }
                                                                            )
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant="p2">
                                                                Make model Public
                                                            </Typography>
                                                        }
                                                        labelPlacement="start"
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            marginLeft: 0,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SelectTime
                                                        name="datePublication"
                                                        label="Publication"
                                                        disabled={!values.isPublic || isPreviewMode}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing="16px">
                                                <Grid item xs={12}>
                                                    <Grid container spacing="8px">
                                                        <Grid item xs={12}>
                                                            <Typography variant="p1SemiBold">
                                                                Dimensions
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormikTextField
                                                                fullWidth
                                                                label="Height (cm)"
                                                                type="number"
                                                                name="dimensions.height"
                                                                variant="outlined"
                                                                disabled={isPreviewMode}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                        max: 300,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 12 : 4}>
                                                            <FormikTextField
                                                                fullWidth
                                                                label="Breasts (cm)"
                                                                name="dimensions.breast"
                                                                type="number"
                                                                variant="outlined"
                                                                disabled={isPreviewMode}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                        max: 300,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 12 : 4}>
                                                            <FormikTextField
                                                                fullWidth
                                                                label="Waist (cm)"
                                                                type="number"
                                                                name="dimensions.waist"
                                                                variant="outlined"
                                                                disabled={isPreviewMode}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                        max: 300,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 12 : 4}>
                                                            <FormikTextField
                                                                fullWidth
                                                                label="Hips (cm)"
                                                                type="number"
                                                                name="dimensions.hips"
                                                                variant="outlined"
                                                                disabled={isPreviewMode}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 1,
                                                                        max: 300,
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing="8px">
                                                        <Grid item xs={12}>
                                                            <Typography variant="p1SemiBold">
                                                                Attributes
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormikSelect
                                                                variant="filled"
                                                                label="Race"
                                                                name="attributes.race"
                                                                fullWidth
                                                                items={modelAttributes?.race}
                                                                disabled={isPreviewMode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormikSelect
                                                                variant="filled"
                                                                label="Hair"
                                                                name="attributes.hair"
                                                                fullWidth
                                                                items={modelAttributes?.hair}
                                                                disabled={isPreviewMode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormikSelect
                                                                variant="filled"
                                                                label="Breast"
                                                                name="attributes.breast"
                                                                fullWidth
                                                                items={modelAttributes?.breast}
                                                                disabled={isPreviewMode}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                component="div"
                                sx={{
                                    position: 'sticky',
                                    bottom: 0,
                                    height: '65px',
                                    background: theme.palette.white,
                                    borderTop: `1px solid ${theme.palette.borders}`,
                                    borderRight: `1px solid ${theme.palette.borders}`,
                                    padding: '8px 20px',
                                    zIndex: 1100,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}>
                                <Button
                                    disabled={
                                        isCreatingModel ||
                                        isUpdatingModel ||
                                        mediaUploadLoading.length > 0
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={submitForm}>
                                    {!isCreatingModel && !isUpdatingModel ? (
                                        isPreviewMode ? (
                                            'Close'
                                        ) : (
                                            'Save'
                                        )
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs
                            style={{
                                flex: 1,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '24px 20px',
                            }}>
                            {values.highRes?.model || values.lowRes?.model ? (
                                <ModelViewer highRes={values.highRes} lowRes={values.lowRes} />
                            ) : (
                                <ModelUploadBox />
                            )}
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
};

export default ModelCreator;
