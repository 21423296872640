import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ReactComponent as ArrowBackIcon } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as VeerlLogo } from '../../assets/logos/veerl_logo.svg';
import { ReactComponent as VeerlLogoMini } from '../../assets/logos/veerl_logo_mini.svg';

import ROUTES from '../../router/routes';
import { AppRoute } from '../../types/routes';
import { getCurrentSection } from '../../helpers/menu';
import SideMenuItem from './SideMenuItem/SideMenuItem';

const drawerWidthOpen = '242px';
const drawerWidthClosed = '60px';

type SideMenuProps = {
    isMenuOpen: boolean;
    closeMenu: () => void;
    isCompact: boolean;
    onChange: (isOpen: boolean) => void;
};

const SideMenu = ({ isMenuOpen, closeMenu, isCompact, onChange }: SideMenuProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const drawerWidth = !isCompact ? drawerWidthOpen : drawerWidthClosed;
    const isBelowMD = useMediaQuery(theme.breakpoints.down('sm'));
    const currentSection = getCurrentSection(location.pathname, ROUTES);

    useEffect(() => {
        if (location.pathname === '/') navigate('/models');
    }, []);

    const navigateToRouting = (path: string) => {
        navigate(path);
        isBelowMD && closeMenu();
    };

    return (
        <Box component="div" width={isBelowMD ? 0 : drawerWidth}>
            <Drawer
                variant={isBelowMD ? 'temporary' : 'persistent'}
                anchor="left"
                open={isBelowMD ? isMenuOpen : true}
                onClose={isBelowMD ? closeMenu : () => {}}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        paddingBottom: '40px',
                        boxSizing: 'border-box',
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            }),
                        overflowX: 'hidden',
                    },
                }}>
                <Box
                    component="div"
                    sx={{
                        padding: '20px',
                        height: '64px',
                    }}>
                    {isCompact ? <VeerlLogoMini /> : <VeerlLogo />}
                </Box>
                <Box component="div" sx={{ height: '20px' }} />
                <List
                    sx={{
                        padding: 0,
                        gap: '80px',
                    }}>
                    {ROUTES.filter((route: AppRoute) => route.isMenu).map(
                        (menuItem: AppRoute, index) => (
                            <SideMenuItem
                                key={menuItem.title}
                                menuItem={menuItem}
                                index={index}
                                currentSection={currentSection}
                                navigateToRouting={navigateToRouting}
                            />
                        )
                    )}
                </List>
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'flex-end',
                        justifyContent: isCompact ? 'center' : 'flex-start',
                        paddingLeft: isCompact ? '0' : '20px',
                        width: '100%',
                    }}>
                    <Box
                        component="div"
                        display="flex"
                        alignItems="center"
                        gap="20px"
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}>
                        <IconButton
                            onClick={() => onChange(!isCompact)}
                            sx={{
                                transform: isCompact ? 'rotate(180deg)' : '',
                                '&:hover': {
                                    borderRadius: '8px',
                                },
                            }}>
                            {!isBelowMD ? (
                                <>
                                    <ArrowBackIcon />
                                    {!isCompact ? (
                                        <>
                                            <Box component="div" width="20px" />
                                            <Typography variant="p2">Hide</Typography>
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                        </IconButton>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default SideMenu;
