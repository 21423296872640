import { Typography, Grid, Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useField } from 'formik';

import UploadedAsset from './UploadedAsset/UploadedAsset';
import UploadButton from './UploadButton/UploadButton';
import TexturesDrawer from './TexturesDrawer/TexturesDrawer';
import { useState } from 'react';
import Link from '../../Link/Link';

const UploadContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

type ModelUploadProps = {
    modelType: 'highRes' | 'lowRes';
    name: string;
    commonID: string;
    disabled?: boolean;
};

const ModelUpload = ({ commonID, modelType, name, disabled }: ModelUploadProps) => {
    const theme = useTheme();

    const [field, meta, helpers] = useField<string>(`${name}.model`);
    const [assetsField, , assetsHelpers] = useField<string[]>(`${name}.assets`);
    const [assetsErrorsField, , assetsErrorsHelpers] = useField<string[]>(`${name}.assetsErrors`);
    const [openTexturesDrawer, setOpenTexturesDrawer] = useState(false);

    const fileUrl = field.value;
    const assets = assetsField.value || [];

    const assetsErrorsLength = assetsErrorsField?.value?.length;

    const onUpload = (fileUrl: string) => {
        helpers.setValue(fileUrl);
    };

    const onAssetsSave = (assets: string[]) => {
        const newAssetsErrors =
            assetsErrorsField?.value?.filter((asset) => assets.includes(asset)) || [];
        assetsErrorsHelpers.setValue(newAssetsErrors);

        assetsHelpers.setValue(assets);
        setOpenTexturesDrawer(false);
    };

    const handleDelete = () => {
        helpers.setValue('');
        assetsHelpers.setValue([]);
        assetsErrorsHelpers.setValue([]);
    };

    return (
        <UploadContainer>
            <Grid container rowGap={fileUrl || disabled ? '16px' : '0px'}>
                <Grid item xs={6}>
                    <Typography
                        variant="p1"
                        color={
                            Boolean(meta.error) ? theme.palette.alert : 'default'
                        }>
                        3D model {modelType === 'highRes' ? 'Android' : 'iOS'}
                    </Typography>
                    {Boolean(meta.error) ? (
                        <Grid item xs={12}>
                            <Typography color="error" fontSize="0.75rem" margin="4px 2px">
                                ({meta.error})
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    {!fileUrl && !disabled ? (
                        <UploadButton
                            name={name + '.model'}
                            commonID={commonID + '-' + modelType}
                            onUpload={onUpload}
                            allowedExtensions={
                                modelType === 'lowRes'
                                    ? ['.usdz']
                                    : ['.obj', '.fbx', '.gltf', '.glb']
                            }
                        />
                    ) : null}
                </Grid>
                {fileUrl ? (
                    <Grid item xs={12}>
                        <UploadedAsset
                            disabled={disabled}
                            fileUrl={fileUrl}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Link
                        onClick={() => setOpenTexturesDrawer(true)}
                        label={`Model textures (${assets?.length})`}
                        disabled={!fileUrl}
                        error={assetsErrorsLength > 0}
                    />
                </Grid>
            </Grid>
            <TexturesDrawer
                type={modelType}
                assets={assets}
                isOpen={openTexturesDrawer}
                onClose={() => setOpenTexturesDrawer(false)}
                onSave={onAssetsSave}
                commonID={commonID}
                disabled={disabled}
            />
        </UploadContainer>
    );
};

export default ModelUpload;
