import { useEffect, useState } from 'react';
import { calculateRowHeightAndPageSize } from '../../../helpers/datagrid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Grid, Typography } from '@mui/material';
import CustomDataGrid from '../../../components/CustomDataGrid/CustomDataGrid';
import { cancelUserSubscription, deleteUserSubscription, getUserAssets } from '../../../api/user';
import { USER_SUBSCRIPTIONS_COLUMNS } from '../../../const/userSubscriptionsColumns';
import AddSubscriptionDrawer from '../../../components/User/AddSubscriptionDrawer';
import { Asset } from '../../../types/assets';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../../atoms/toastAtom';
import { AxiosError } from 'axios';

type UserSubscriptionsProps = {
    userID: string;
    userName: string;
    triggerAction?: boolean;
};

const UserSubscriptions = ({ userID, userName, triggerAction }: UserSubscriptionsProps) => {
    const queryClient = useQueryClient();
    const setToastState = useSetAtom(toastAtom);
    const rowProperties = calculateRowHeightAndPageSize(320, true);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<Asset | null>();
    const [showConfirmCancelDialog, setConfirmCancelDialog] = useState<Asset | null>();
    const [showAddSubscriptionDrawer, setShowAddSubscriptionDrawer] = useState<boolean>(false);

    const { data: userAssets, isLoading } = useQuery(['userAssets', userID], () =>
        getUserAssets(userID)
    );

    const { mutateAsync: mutateRemoveAsset, isLoading: isRemovingAsset } = useMutation(
        ['user', userID, 'asset', showConfirmDeleteDialog?.id],
        () => deleteUserSubscription(userID, showConfirmDeleteDialog?.id!!),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['userAssets', userID]);
                setToastState({
                    message: 'Subscription removed successfully!',
                    severity: 'success',
                });
            },
            onError: (error: AxiosError) => {
                if (error?.message) {
                    setToastState({
                        message: error.message,
                        severity: 'error',
                    });
                }
            },
        }
    );

    const { mutateAsync: mutateCancelSubcription, isLoading: isCancellingSubscription } =
        useMutation(
            ['user', userID, 'asset', showConfirmCancelDialog?.id],
            () => cancelUserSubscription(userID, showConfirmCancelDialog?.id!!),
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(['userAssets', userID]);
                    setToastState({
                        message: 'Subscription cancelled successfully!',
                        severity: 'success',
                    });
                },
                onError: (error: AxiosError) => {
                    if (error?.message) {
                        setToastState({
                            message: error.message,
                            severity: 'error',
                        });
                    }
                },
            }
        );

    useEffect(() => {
        if (triggerAction !== undefined) setShowAddSubscriptionDrawer(true);
    }, [triggerAction]);

    const onRemoveAssetHandler = async () => {
        try {
            await mutateRemoveAsset();
        } catch (err) {}

        setShowConfirmDeleteDialog(null);
    };

    const onCancelAssetHandler = async () => {
        try {
            await mutateCancelSubcription();
        } catch (err) {}

        setConfirmCancelDialog(null);
    };
    let subscriptions: Asset[] = [];

    if (userAssets?.subscriptions.tier) {
        subscriptions = [userAssets.subscriptions.tier];
    }

    if (userAssets?.subscriptions.collections) {
        subscriptions = subscriptions.concat(userAssets.subscriptions.collections);
    }

    if (userAssets?.subscriptions.models) {
        subscriptions = subscriptions.concat(userAssets.subscriptions.models);
    }

    return (
        <Grid
            container
            gap="16px"
            sx={{
                padding: '0px 20px 16px 20px',
            }}>
            <Grid item xs={12}>
                <CustomDataGrid
                    tableName="userSubscriptions"
                    rowProperties={rowProperties}
                    height="calc(100vh - 310px)"
                    disableColumnFilter
                    rows={subscriptions}
                    columns={USER_SUBSCRIPTIONS_COLUMNS({
                        onDelete: (asset) => setShowConfirmDeleteDialog(asset),
                        onCancel: (asset) => setConfirmCancelDialog(asset),
                    })}
                    dataProcess="client"
                    loading={isLoading}
                    localeText={{
                        noRowsLabel: 'No subscriptions found.',
                        noResultsOverlayLabel: 'No subscriptions found.',
                    }}
                />
            </Grid>
            <AddSubscriptionDrawer
                userID={userID}
                userAssets={subscriptions}
                isOpen={showAddSubscriptionDrawer}
                onClose={() => setShowAddSubscriptionDrawer(false)}
            />
            <CustomDialog
                open={!!showConfirmDeleteDialog || !!showConfirmCancelDialog}
                onClose={() =>
                    !!showConfirmDeleteDialog
                        ? setShowConfirmDeleteDialog(null)
                        : setConfirmCancelDialog(null)
                }
                onConfirm={
                    !!showConfirmDeleteDialog
                        ? () => onRemoveAssetHandler()
                        : () => onCancelAssetHandler()
                }
                title={!!showConfirmDeleteDialog ? 'Remove Subscription' : 'Cancel Subscription'}
                confirmLabel={!!showConfirmDeleteDialog ? 'Remove' : 'Cancel'}
                isLoading={isRemovingAsset || isCancellingSubscription}
                variant="danger"
                content={
                    <Typography variant="p2" textAlign="center">
                        Are you sure that you want to{' '}
                        {showConfirmDeleteDialog ? 'delete' : 'cancel'} subscription{' '}
                        <b>
                            {showConfirmDeleteDialog?.reference?.collection?.name ||
                                showConfirmDeleteDialog?.reference?.model?.name}
                        </b>{' '}
                        from <b>{userName}</b> assets?
                    </Typography>
                }
            />
        </Grid>
    );
};

export default UserSubscriptions;
