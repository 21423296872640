import { Box, Button, useTheme } from '@mui/material';

type DrawerFooterProps = {
    onSave?: () => void;
    saveLabel?: string;
    anchor?: 'left' | 'right';
    children?: React.ReactNode;
};

const DrawerFooter = ({
    children,
    onSave,
    saveLabel = 'Save',
    anchor = 'right',
}: DrawerFooterProps) => {
    const theme = useTheme();
    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                right: anchor === 'right' ? 0 : 'auto',
                left: anchor === 'left' ? 0 : 'auto',
                width: '430px',
                background: 'white',
                flex: 1,
                zIndex: 100,
            }}>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '8px 20px',
                    borderTop: `1px solid ${theme.palette.borders}`,
                }}>
                {children ? (
                    children
                ) : (
                    <Button variant="contained" onClick={onSave}>
                        {saveLabel}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default DrawerFooter;
