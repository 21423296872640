import CustomCard from '../../../components/CustomCard/CustomCard';

import { ReactComponent as AboutIcon } from '../../../assets/icons/users.svg';
import { Box, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { User, UserPersonalData } from '../../../types/user';
import { Formik, FormikHelpers } from 'formik';
import FormikTextField from '../../../components/Formik/FormikTextField';
import { UserAboutValidationSchema } from '../../../validation/user';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../../atoms/toastAtom';
import { useMutation } from 'react-query';
import { updateUserPersonalData } from '../../../api/user';
import { APIError } from '../../../axios/axiosVeerlInstance';
import FormikSwitch from '../../../components/Formik/FormikSwitch';

type UserAboutProps = {
    userData: User;
};

const UserAbout = ({ userData }: UserAboutProps) => {
    const theme = useTheme();
    const setToastState = useSetAtom(toastAtom);

    const updateMutation = useMutation(
        ['user', userData.id],
        (userPersonalData: UserPersonalData) =>
            updateUserPersonalData(userData.id, userPersonalData),
        {
            onSuccess: (data) => {
                setToastState({
                    message: 'User updated successfully!',
                    severity: 'success',
                });
            },
        }
    );

    const onSubmitHandler = async (
        values: UserPersonalData,
        helpers: FormikHelpers<UserPersonalData>
    ) => {
        try {
            await updateMutation.mutateAsync(values);
        } catch (error) {
            const apiError = error as APIError;
            if (apiError.message) {
                helpers.setFieldError('email', apiError.message);
            }
        }
    };

    return (
        <Formik
            initialValues={
                {
                    email: userData?.email,
                    firstName: userData?.firstName,
                    emailConfirmed: userData?.emailConfirmed,
                    superUser: userData?.superUser,
                } as UserPersonalData
            }
            onSubmit={onSubmitHandler}
            validationSchema={UserAboutValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}>
            {({ submitForm }) => (
                <CustomCard
                    headline="About"
                    icon={<AboutIcon />}
                    onSubmit={submitForm}
                    isLoading={updateMutation.isLoading}>
                    <Grid container gap="24px">
                        <Grid item xs={12} display="flex" alignItems="center" gap="12px">
                            <Box
                                component="div"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                style={{
                                    width: userData?.authMethod === 'oauth' ? '40px' : '50px',
                                    height: userData?.authMethod === 'oauth' ? '40px' : '50px',
                                    minWidth: userData?.authMethod === 'oauth' ? '40px' : '50px',
                                    minHeight: userData?.authMethod === 'oauth' ? '40px' : '50px',
                                    borderRadius: '100%',
                                    backgroundColor: theme.palette.accentMagenta,
                                }}>
                                <Typography variant="p1" color={theme.palette.white}>
                                    {userData?.firstName[0]}
                                </Typography>
                            </Box>
                            <Box component="div" display="flex" flexDirection="column">
                                <Typography variant="p2">
                                    Authentication method:{' '}
                                    <Typography variant="p2SemiBold">
                                        {userData?.authMethod}
                                    </Typography>
                                </Typography>
                                {userData.authMethod === 'email' ? (
                                    <FormControlLabel
                                        control={<FormikSwitch name="emailConfirmed" />}
                                        label={
                                            <Typography variant="p2SemiBold">
                                                Email confirmed
                                            </Typography>
                                        }
                                        labelPlacement="start"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginLeft: 0,
                                        }}
                                    />
                                ) : null}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<FormikSwitch name="superUser" />}
                                label={<Typography variant="p2SemiBold">Superuser</Typography>}
                                labelPlacement="start"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '140px',
                                    marginLeft: '4px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing="16px">
                                <Grid item xs={6}>
                                    <FormikTextField
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikTextField
                                        fullWidth
                                        disabled={userData.authMethod === 'oauth'}
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomCard>
            )}
        </Formik>
    );
};

export default UserAbout;
