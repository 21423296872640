export const BASE_PAGE_SIZE_OPTIONS = [5, 10, 15, 25, 50, 100];
export const MIN_ROW_HEIGHT = 80;
const HEADER_HEIGHT = 56;
const PAGINATION_HEIGHT = 52;

export type RowProperties = {
    pageSize: number;
    rowHeight: number;
};

export const calculateRowHeightAndPageSize = (
    adjustment: number,
    scrollbar?: boolean
): RowProperties => {
    const viewportHeight = window.innerHeight;
    const heightNumber = viewportHeight - adjustment;

    const availableHeight = heightNumber - HEADER_HEIGHT - PAGINATION_HEIGHT - (scrollbar ? 6 : 0);

    let rowsCount = Math.floor(availableHeight / MIN_ROW_HEIGHT);
    let rowHeight = availableHeight / rowsCount - (rowsCount - 1);

    if (rowHeight < MIN_ROW_HEIGHT) {
        rowHeight = MIN_ROW_HEIGHT;
        rowsCount = Math.floor(availableHeight / rowHeight);
    }

    return {
        pageSize: rowsCount,
        rowHeight: rowHeight,
    };
};
