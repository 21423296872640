import CustomCard from '../../../components/CustomCard/CustomCard';
import { User } from '../../../types/user';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import { useMutation } from 'react-query';
import { deleteUser } from '../../../api/user';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../../atoms/toastAtom';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';

type DeleteUserProps = {
    userData: User;
};

const DeleteUser = ({ userData }: DeleteUserProps) => {
    const setToastState = useSetAtom(toastAtom);
    const theme = useTheme();
    const navigate = useNavigate();

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const deleteMutation = useMutation(['user', userData.id], () => deleteUser(userData.id), {
        onSuccess: (data) => {
            navigate('/users');
            setToastState({
                message: 'User deleted successfully!',
                severity: 'success',
            });
        },
        onError: (error: AxiosError) => {
            if (error?.message) {
                setToastState({
                    message: error.message,
                    severity: 'error',
                });
            }
        },
    });

    return (
        <>
            <CustomCard
                headline="Delete account"
                icon={<DeleteIcon />}
                showFooter={false}
                isLoading={deleteMutation.isLoading}>
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        gap: '24px',
                    }}>
                    <Typography variant="p2">
                        Proceed if you are sure you want to delete this account. This action is not
                        reversable.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: theme.palette.accentMagenta,
                            color: theme.palette.white,

                            '&:hover, &:active, &:focus': {
                                backgroundColor: theme.palette.accentMagenta,
                            },
                        }}
                        onClick={() => setShowConfirmDeleteDialog(true)}
                        disabled={deleteMutation.isLoading}>
                        {deleteMutation.isLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            'Delete account'
                        )}
                    </Button>
                </Box>
            </CustomCard>
            <CustomDialog
                open={showConfirmDeleteDialog}
                onClose={() => setShowConfirmDeleteDialog(false)}
                onConfirm={() => deleteMutation.mutate()}
                title="Delete account"
                confirmLabel="Delete account"
                variant="danger"
                content={
                    <Typography variant="p2" textAlign="center">
                        Upon confirming your decision to delete this account, the process to
                        permanently remove this account and all associated data will be initiated.
                        It's important to understand that this action is irreversible.
                    </Typography>
                }
            />
        </>
    );
};

export default DeleteUser;
