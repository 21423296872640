import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Formik, FormikHelpers } from 'formik';

import {
    Button,
    Box,
    Grid,
    useTheme,
    CircularProgress,
    Drawer,
    Typography,
    FormControlLabel,
    Tabs,
    Tab,
    Tooltip,
} from '@mui/material';
import FormikTextField from '../../components/Formik/FormikTextField';
import { customValidate } from '../../helpers/validation';
import { useAtomValue, useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { useEffect, useState } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import {
    Collection,
    CollectionApplyDimensionsMutation,
    CollectionFormData,
    CollectionMutation,
    INITIAL_COLLECTION_FORM_DATA,
} from '../../types/collection';
import {
    createCollection,
    updateApplyDimensionsCollection,
    updateCollection,
} from '../../api/collection';
import { collectionValidationSchema } from '../../validation/collectionSchema';
import DrawerHeader from '../../components/Drawer/DrawerHeader';
import DrawerContent from '../../components/Drawer/DrawerContent';
import DrawerFooter from '../../components/Drawer/DrawerFooter';
import { APIError } from '../../axios/axiosVeerlInstance';
import MediaUpload from '../../components/ModelCreator/MediaUpload/MediaUpload';
import { mediaUploadLoadingAtom } from '../../atoms/mediaUploadLoadingAtom';
import FormikSwitch from '../../components/Formik/FormikSwitch';
import { getModelAttributes } from '../../api/model';
import FormikSelect from '../../components/Formik/FormikSelect';
import AssistantForm from './AssistantForm/AssistantForm';
import { AssistantFormData } from '../../types/assistant';
import { createCollectionAssistant, updateCollectionAssistant } from '../../api/assistant';

type CollectionCreatorProps = {
    editingCollection: Collection | null;
    isOpen: boolean;
    onClose: () => void;
};

const CollectionCreator = ({ editingCollection, isOpen, onClose }: CollectionCreatorProps) => {
    const theme = useTheme();
    const queryClient = useQueryClient();
    const mediaUploadLoading = useAtomValue(mediaUploadLoadingAtom);

    const cid = editingCollection?.id;
    const isEditMode = !!cid;

    const setToastState = useSetAtom(toastAtom);

    const [initialFormData, setInitialFormData] = useState<CollectionFormData>({
        ...INITIAL_COLLECTION_FORM_DATA,
    });
    const [activeTab, setActiveTab] = useState<'base' | 'assistant'>('base');

    const { data: modelAttributes } = useQuery('modelAttributes', getModelAttributes);

    useEffect(() => {
        if (isOpen && editingCollection?.id) {
            setInitialFormData({
                name: editingCollection.name,
                description: editingCollection.description,
                price: editingCollection.price,
                sortOrder: editingCollection.sortOrder,
                thumbnail: editingCollection.thumbnail || '',
                background: editingCollection.background || '',
                isPublic: editingCollection.isPublic || false,
                showOnLandingPage: editingCollection.showOnLandingPage || false,
                banner: {
                    thumbnail: editingCollection.banner.thumbnail || '',
                    background: editingCollection.banner.background || '',
                },
                dimensions: {
                    height: editingCollection?.dimensions?.height || null,
                    breast: editingCollection?.dimensions?.breast || null,
                    waist: editingCollection?.dimensions?.waist || null,
                    hips: editingCollection?.dimensions?.hips || null,
                },
                attributes: {
                    race: editingCollection?.attributes?.race || null,
                    hair: editingCollection?.attributes?.hair || null,
                    breast: editingCollection?.attributes?.breast || null,
                },
                assistant: {
                    name: editingCollection.assistant?.name || '',
                    active: editingCollection.assistant?.active || false,
                    description: editingCollection.assistant?.description || '',
                    prompt: editingCollection.assistant?.prompt || '',
                    welcomeMessage: editingCollection.assistant?.welcomeMessage || '',
                    avatar: editingCollection.assistant?.avatar || '',
                    reference: {
                        modelID: editingCollection.assistant?.reference?.model?.id || '',
                        asset: editingCollection.assistant?.reference?.asset || '',
                    },
                    voice: editingCollection.assistant?.voice || '',
                },
            });
        } else if (isOpen) {
            setInitialFormData({ ...INITIAL_COLLECTION_FORM_DATA });
        }
    }, [isOpen]);

    const { mutateAsync: mutateCreateCollection, isLoading: isCreatingCollection } = useMutation(
        'createCollection',
        createCollection,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('collections');
                onClose();
                setToastState({
                    message: 'Collection created successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const {
        mutateAsync: mutateCreateCollectionAssistant,
        isLoading: isCreatingCollectionAssistant,
    } = useMutation(
        'createCollectionAssistant',
        (collectionAssistantFormData: AssistantFormData) =>
            createCollectionAssistant(collectionAssistantFormData, cid),
        {
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const {
        mutateAsync: mutateUpdateCollectionAssistant,
        isLoading: isUpdatingCollectionAssistant,
    } = useMutation(
        'updateCollectionAssistant',
        (collectionAssistantFormData: AssistantFormData) =>
            updateCollectionAssistant(collectionAssistantFormData, cid),
        {
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const { mutateAsync: mutateUpdateCollection, isLoading: isUpdatingCollection } = useMutation(
        'updateCollection',
        ({ collectionFormData, id }: CollectionMutation) =>
            updateCollection(collectionFormData, id),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('collections');
                onClose();
                setToastState({
                    message: 'Collection updated successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const {
        mutateAsync: mutateUpdateApplyDimensionsCollection,
        isLoading: isUpdatingDimensionsCollection,
    } = useMutation(
        'updateCollection',
        ({ collectionFormData, id }: CollectionApplyDimensionsMutation) =>
            updateApplyDimensionsCollection(collectionFormData, id),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('collections');
                setToastState({
                    message: 'Collection dimensions and attributes applied successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const onSubmitHandler = async (
        values: CollectionFormData,
        helpers: FormikHelpers<CollectionFormData>
    ) => {
        if (!values?.price && values?.price !== 0) values.price = null;
        if (!values?.dimensions?.breast) values.dimensions.breast = null;
        if (!values?.dimensions?.height) values.dimensions.height = null;
        if (!values?.dimensions?.waist) values.dimensions.waist = null;
        if (!values?.dimensions?.hips) values.dimensions.hips = null;
        if (!values?.attributes?.breast) values.attributes.breast = null;
        if (!values?.attributes?.hair) values.attributes.hair = null;
        if (!values?.attributes?.race) values.attributes.race = null;

        if (!values?.sortOrder && values?.sortOrder !== 0) values.sortOrder = 100;

        if (isEditMode) {
            try {
                if (values.assistant) {
                    if (!values.assistant.reference.asset) {
                        delete values.assistant.reference.asset;
                    }

                    if (!editingCollection?.assistant) {
                        await mutateCreateCollectionAssistant(values.assistant);
                    } else {
                        await mutateUpdateCollectionAssistant(values.assistant);
                    }
                }

                delete values.assistant;
                await mutateUpdateCollection({ collectionFormData: values, id: cid });
            } catch (err) {}
        } else {
            try {
                await mutateCreateCollection(values);
            } catch (err: any) {
                const apiError = err as APIError;
                if (apiError?.message?.includes('exists')) {
                    helpers.setFieldError('name', 'Collection with this name already exists');
                }
            }
        }
    };

    const onApplyToAll = async (values: CollectionFormData) => {
        if (!cid) return;

        if (!values?.dimensions?.breast) values.dimensions.breast = null;
        if (!values?.dimensions?.height) values.dimensions.height = null;
        if (!values?.dimensions?.waist) values.dimensions.waist = null;
        if (!values?.dimensions?.hips) values.dimensions.hips = null;
        if (!values?.attributes?.breast) values.attributes.breast = null;
        if (!values?.attributes?.hair) values.attributes.hair = null;
        if (!values?.attributes?.race) values.attributes.race = null;

        try {
            await mutateUpdateApplyDimensionsCollection({
                collectionFormData: {
                    dimensions: values.dimensions,
                    attributes: values.attributes,
                },
                id: cid,
            });
        } catch (err) {}
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <DrawerHeader
                onClose={onClose}
                label={
                    <Box component="div" display="flex" alignItems="center" gap="16px">
                        <Typography variant="p1SemiBold">
                            {isEditMode ? 'Edit collection' : 'Create collection'}
                        </Typography>
                    </Box>
                }
            />
            <DrawerContent>
                <Formik
                    enableReinitialize
                    initialValues={initialFormData}
                    onSubmit={onSubmitHandler}
                    validate={(values) => customValidate(values, collectionValidationSchema, true)}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={collectionValidationSchema}>
                    {({ submitForm, values, setFieldValue, setFieldError }) => (
                        <Grid
                            item
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <Box
                                component="div"
                                sx={{
                                    borderRight: `1px solid ${theme.palette.borders}`,
                                    width: '430px',
                                    padding: '0px 24px 20px 20px',
                                }}>
                                <Box
                                    component="div"
                                    sx={{
                                        borderBottom: isEditMode ? 1 : 0,
                                        borderColor: 'divider',
                                        marginBottom: isEditMode ? '16px' : 0,
                                    }}>
                                    {isEditMode ? (
                                        <Tabs
                                            value={activeTab}
                                            onChange={(e: any, newValue: any) =>
                                                setActiveTab(newValue)
                                            }>
                                            <Tab label="Base info" value="base" />
                                            <Tab label="Assistant" value="assistant" />
                                        </Tabs>
                                    ) : null}
                                </Box>
                                <Grid
                                    item
                                    xs
                                    style={{
                                        paddingBottom: 0,
                                    }}>
                                    <Grid
                                        container
                                        sx={{
                                            '& > .MuiGrid-item': {
                                                padding: '16px 0',
                                                borderBottom: '1px solid ' + theme.palette.borders,
                                            },
                                            '& > .MuiGrid-item:first-child': {
                                                paddingTop: 0,
                                            },
                                            '& > .MuiGrid-item:last-child': {
                                                borderBottom: 'none',
                                                paddingBottom: 0,
                                            },
                                        }}>
                                        {activeTab === 'base' ? (
                                            <Grid item xs={12}>
                                                <Grid container spacing="16px">
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            fullWidth
                                                            label="Name"
                                                            name="name"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            fullWidth
                                                            label="Subscription price ($)"
                                                            type="number"
                                                            name="price"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            fullWidth
                                                            type="number"
                                                            label="Sort order"
                                                            name="sortOrder"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            fullWidth
                                                            multiline
                                                            rows={12}
                                                            inputProps={{
                                                                maxLength: 2048,
                                                            }}
                                                            label="Description"
                                                            name="description"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing="16px">
                                                            <Grid item xs={12}>
                                                                <Grid container spacing="8px">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="p1SemiBold">
                                                                            Dimensions
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikTextField
                                                                            fullWidth
                                                                            label="Height (cm)"
                                                                            type="number"
                                                                            name="dimensions.height"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 300,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikTextField
                                                                            fullWidth
                                                                            label="Breasts (cm)"
                                                                            name="dimensions.breast"
                                                                            type="number"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 300,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikTextField
                                                                            fullWidth
                                                                            label="Waist (cm)"
                                                                            type="number"
                                                                            name="dimensions.waist"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 300,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikTextField
                                                                            fullWidth
                                                                            label="Hips (cm)"
                                                                            type="number"
                                                                            name="dimensions.hips"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 300,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing="8px">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="p1SemiBold">
                                                                            Attributes
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikSelect
                                                                            variant="filled"
                                                                            label="Race"
                                                                            name="attributes.race"
                                                                            fullWidth
                                                                            items={
                                                                                modelAttributes?.race
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikSelect
                                                                            variant="filled"
                                                                            label="Hair"
                                                                            name="attributes.hair"
                                                                            fullWidth
                                                                            items={
                                                                                modelAttributes?.hair
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormikSelect
                                                                            variant="filled"
                                                                            label="Breast"
                                                                            name="attributes.breast"
                                                                            fullWidth
                                                                            items={
                                                                                modelAttributes?.breast
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {isEditMode ? (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={
                                                                    isUpdatingDimensionsCollection
                                                                }
                                                                sx={{
                                                                    marginTop: '16px',
                                                                }}
                                                                onClick={() =>
                                                                    onApplyToAll(values)
                                                                }>
                                                                Apply to all
                                                            </Button>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <FormikSwitch name="isPublic" />
                                                            }
                                                            label={
                                                                <Typography variant="p2">
                                                                    Collection public
                                                                </Typography>
                                                            }
                                                            labelPlacement="start"
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                paddingLeft: '4px',
                                                                justifyContent: 'space-between',
                                                                marginLeft: 0,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <FormikSwitch name="showOnLandingPage" />
                                                            }
                                                            label={
                                                                <Typography variant="p2">
                                                                    Show on LP
                                                                </Typography>
                                                            }
                                                            labelPlacement="start"
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                paddingLeft: '4px',
                                                                justifyContent: 'space-between',
                                                                marginLeft: 0,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MediaUpload
                                                            commonID={cid + ''}
                                                            icon={<ImageIcon />}
                                                            title="Banner background"
                                                            availableFormats={[
                                                                '.png',
                                                                '.jpg',
                                                                '.jpeg',
                                                            ]}
                                                            name="banner.background"
                                                            description="Upload .png, .jpg, .jpeg file"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MediaUpload
                                                            commonID={cid + ''}
                                                            icon={<ImageIcon />}
                                                            title="Banner thumbnail"
                                                            availableFormats={['.png']}
                                                            name="banner.thumbnail"
                                                            description="Upload .png file"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MediaUpload
                                                            commonID={cid + ''}
                                                            icon={<ImageIcon />}
                                                            title="Collection thumbnail"
                                                            availableFormats={['.png']}
                                                            name="thumbnail"
                                                            description="Upload .png file"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MediaUpload
                                                            commonID={cid + ''}
                                                            icon={<ImageIcon />}
                                                            title="Collection background"
                                                            availableFormats={['.png']}
                                                            name="background"
                                                            description="Upload .png, .jpg, .jpeg file"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <AssistantForm collectionID={cid + ''} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                            <DrawerFooter>
                                <Button
                                    disabled={
                                        isCreatingCollection ||
                                        isUpdatingCollection ||
                                        isCreatingCollectionAssistant ||
                                        isUpdatingCollectionAssistant ||
                                        mediaUploadLoading.length > 0
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={submitForm}>
                                    {!isCreatingCollection &&
                                    !isUpdatingCollection &&
                                    !isCreatingCollectionAssistant &&
                                    !isUpdatingCollectionAssistant ? (
                                        'Save'
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </Button>
                            </DrawerFooter>
                        </Grid>
                    )}
                </Formik>
            </DrawerContent>
        </Drawer>
    );
};

export default CollectionCreator;
