import { Grid, Typography, useTheme } from '@mui/material';
import FormikTextField from '../Formik/FormikTextField';

type DateRangeProps = {
    label: string;
    name: string;
    disabled?: boolean;
};

const DateRange = ({ label, name, disabled }: DateRangeProps) => {
    const theme = useTheme();

    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" gap="4px">
                <Grid item xs>
                    <FormikTextField
                        fullWidth
                        type="date"
                        name={`${name}.from`}
                        label={`${label} from`}
                        disabled={disabled}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="p1" color={theme.palette.black54}>
                        -
                    </Typography>
                </Grid>
                <Grid item xs>
                    <FormikTextField
                        fullWidth
                        type="date"
                        name={`${name}.to`}
                        label={`${label} to`}
                        disabled={disabled}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DateRange;
