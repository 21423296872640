import { useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import SideMenu from '../SideMenu/SideMenu';
import Navbar from '../Navbar/Navbar';

type LayoutProps = {
    children?: React.ReactElement | React.ReactElement[];
};

const drawerWidthOpen = '242px';
const drawerWidthClosed = '60px';

const Layout = ({ children }: LayoutProps) => {
    const theme = useTheme();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const [isCompact, setIsCompact] = useState<boolean>(false);

    const drawerWidth = !isCompact ? drawerWidthOpen : drawerWidthClosed;
    const isBelowMD = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box component="div" sx={{ flexGrow: 1, display: 'flex' }}>
            <SideMenu
                isCompact={isCompact}
                onChange={(isOpen) => setIsCompact(isOpen)}
                isMenuOpen={isMobileMenuOpen}
                closeMenu={() => setIsMobileMenuOpen(false)}
            />
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: !isBelowMD ? `calc(100vw - ${drawerWidth})` : '100vw',
                }}>
                <Navbar openMenu={() => setIsMobileMenuOpen(true)} />
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        height: 'calc(100vh - 65px)',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default Layout;
