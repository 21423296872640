import { Drawer, Typography, Box, Grid, FormControlLabel } from '@mui/material';
import DrawerHeader from '../../Drawer/DrawerHeader';
import DrawerContent from '../../Drawer/DrawerContent';
import DrawerFooter from '../../Drawer/DrawerFooter';
import { ModelDiscount } from '../../../types/model';
import { Formik } from 'formik';
import FormikTextField from '../../Formik/FormikTextField';
import SelectTime from '../../SelectTime/SelectTime';
import { DiscountValidationSchema } from '../../../validation/modelSchema';
import FormikSwitch from '../../Formik/FormikSwitch';

type TexturesDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    discount: ModelDiscount;
    onSave: (discount: ModelDiscount) => void;
    disabled?: boolean;
};

const DiscountDrawer = ({ isOpen, onClose, discount, onSave }: TexturesDrawerProps) => {
    const onSubmitHandler = (values: ModelDiscount) => {
        onSave(values);
    };

    return (
        <Drawer anchor="left" open={isOpen} onClose={onClose}>
            <Formik
                initialValues={JSON.parse(JSON.stringify(discount))}
                onSubmit={onSubmitHandler}
                validationSchema={DiscountValidationSchema}
                validateOnChange={false}
                validateOnBlur={false}>
                {({ submitForm, values }) => (
                    <>
                        <DrawerHeader
                            onClose={onClose}
                            label={
                                <Box component="div" display="flex" alignItems="center" gap="16px">
                                    <Typography variant="p1SemiBold">Set discount</Typography>
                                </Box>
                            }></DrawerHeader>
                        <DrawerContent>
                            <Box
                                component="div"
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                gap="16px">
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<FormikSwitch name="active" />}
                                        label={
                                            <Typography variant="p2">Discount active</Typography>
                                        }
                                        labelPlacement="start"
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginLeft: 0,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikTextField
                                        fullWidth
                                        label="Discount (%)"
                                        disabled={!values.active}
                                        name="value"
                                        type="number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="p1">Discount start</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectTime
                                        name="dateStart"
                                        label="Start"
                                        disabled={!values.active}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="p1">Discount end</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectTime
                                        name="dateEnd"
                                        label="End"
                                        disabledDate={values.dateStart}
                                        disabled={!values.active}
                                    />
                                </Grid>
                            </Box>
                        </DrawerContent>
                        <DrawerFooter onSave={submitForm} anchor="left" />
                    </>
                )}
            </Formik>
        </Drawer>
    );
};

export default DiscountDrawer;
