import { GridColDef, GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid';
import { format } from 'date-fns';

import { Model } from '../types/model';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import CustomChip from '../components/CustomChip/CustomChip';

import { ReactComponent as EditIcon } from '../assets/icons/settings.svg';
import { ReactComponent as ClockIcon } from '../assets/icons/clock.svg';
import { ReactComponent as ArrowBackOutlinedIcon } from '../assets/icons/arrow-back-outlined.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';

type ModelsColumnsCallbacks = {
    currentTab: 'active' | 'archived';
    onEdit: (row: Model) => void;
    onArchive: (row: Model) => void;
    onDelete: (row: Model) => void;
};

export const MODELS_TABLE_COLUMNS = ({
    currentTab,
    onEdit,
    onArchive,
    onDelete,
}: ModelsColumnsCallbacks): GridColDef<Model>[] => [
    {
        field: 'thumbnail',
        headerName: '',
        display: 'flex',
        sortable: false,
        width: 80,
        renderCell: (params) => (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                    width: '64px',
                    height: '64px',
                    minWidth: '64px',
                    minHeight: '64px',
                    borderRadius: '8px',
                    backgroundImage: `url(${params.value})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            />
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 220,
        display: 'flex',
        resizable: true,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.value as string}>
                    <Typography variant="p2SemiBold">{params.value}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'price',
        headerName: 'Price',
        minWidth: 120,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    style={{ alignItems: 'center', height: '100%' }}>
                    <Chip
                        label={params.value ? `${params.value}$` : 'Free'}
                        variant={params.value ? 'primary' : 'primaryOutlined'}
                    />
                    {params.row.discount?.active && (
                        <CustomChip
                            tooltip={
                                params.row.discount.dateStart && params.row.discount.dateEnd
                                    ? `${format(new Date(params.row.discount.dateStart), 'dd/MM/yyyy, hh:mm a')} - ${format(new Date(params.row.discount.dateEnd), 'dd/MM/yyyy, hh:mm a')}`
                                    : ''
                            }
                            label={`-${params.row.discount.value}%`}
                            variant="primary"
                            sx={{ backgroundColor: '#06BE7E' }}
                        />
                    )}
                </Box>
            );
        },
    },
    {
        field: 'description',
        headerName: 'Description',
        minWidth: 140,
        display: 'flex',
        sortComparator: (v1, v2) => v1[0].localeCompare(v2[0]),
        renderCell: (params) => {
            if (params.value.length > 1) {
                return (
                    <Box component="div" display="flex" gap="2px">
                        <CustomChip
                            label={
                                params.value[0].length > 10
                                    ? `${params.value[0].substring(0, 10)}...`
                                    : params.value[0]
                            }
                            variant="secondary"
                        />
                        <CustomChip
                            label={`+${params.value.length - 1}`}
                            tooltip={params.value.slice(1).join(', ')}
                            variant="secondary"
                        />
                    </Box>
                );
            } else {
                return (
                    <CustomChip
                        label={
                            params.value[0].length > 10
                                ? `${params.value[0].substring(0, 10)}...`
                                : params.value[0]
                        }
                        variant="secondary"
                    />
                );
            }
        },
    },
    {
        field: 'androidModelSize',
        headerName: 'Android size',
        minWidth: 130,
        display: 'flex',
        renderCell: (params) => {
            const sizeInMB = params.value ? (params.value / (1024 * 1024)).toFixed(2) : 0;
            return <Chip label={`${sizeInMB} MB`} variant="secondary" />;
        },
    },
    {
        field: 'iosModelSize',
        headerName: 'iOS size',
        minWidth: 130,
        display: 'flex',
        renderCell: (params) => {
            const sizeInMB = params.value ? (params.value / (1024 * 1024)).toFixed(2) : 0;
            return <Chip label={`${sizeInMB} MB`} variant="secondary" />;
        },
    },
    {
        field: 'rarity',
        headerName: 'Rarity',
        minWidth: 110,
        display: 'flex',
        renderCell: (params) => {
            return <CustomChip label={params.value} variant="secondary" />;
        },
    },
    {
        field: 'pose',
        headerName: 'Pose',
        minWidth: 145,
        display: 'flex',
        sortComparator: (v1, v2) => v1[0].localeCompare(v2[0]),
        renderCell: (params) => {
            if (params.value.length > 1) {
                return (
                    <Box component="div" display="flex" gap="2px">
                        <CustomChip
                            label={
                                params.value[0].length > 10
                                    ? `${params.value[0].substring(0, 10)}...`
                                    : params.value[0]
                            }
                            variant="secondary"
                        />
                        <CustomChip
                            label={`+${params.value.length - 1}`}
                            tooltip={params.value.slice(1).join(', ')}
                            variant="secondary"
                        />
                    </Box>
                );
            } else {
                return (
                    <CustomChip
                        label={
                            params.value[0].length > 10
                                ? `${params.value[0].substring(0, 10)}...`
                                : params.value[0]
                        }
                        variant="secondary"
                    />
                );
            }
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 110,
        display: 'flex',
        renderCell: (params) => {
            return <CustomChip label={params.value} variant="secondary" />;
        },
    },
    {
        field: 'likes.count',
        headerName: 'Likes',
        minWidth: 110,
        display: 'flex',
        renderCell: (params) => {
            return <CustomChip label={params.row.likes.count} variant="secondary" />;
        },
    },
    {
        field: 'sortOrder',
        headerName: 'Sort order',
        width: 110,
        display: 'flex',
        renderCell: (params) => {
            return <CustomChip label={params.row.sortOrder} variant="secondary" />;
        },
    },
    {
        field: 'dimensions',
        headerName: 'Dimensions',
        minWidth: 140,
        display: 'flex',
        renderCell: (params: GridRenderCellParams) => {
            const { height, breast, waist, hips } = params.row.dimensions;
            const dimensions = `${height} cm ${breast}/${waist}/${hips}`;
            return (
                <Box component="div">
                    <Typography variant="p2">{dimensions}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'tier',
        headerName: 'Tier',
        minWidth: 90,
        display: 'flex',
        renderCell: (params) => {
            return <Chip label={'Tier ' + params.value} variant="secondary" />;
        },
    },
    {
        field: 'isPublic',
        headerName: 'Public',
        minWidth: 90,
        display: 'flex',
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'isFreeForUsers',
        headerName: 'Free for Users',
        minWidth: 130,
        display: 'flex',
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'isFreeForGuests',
        headerName: 'Free for Guests',
        minWidth: 130,
        display: 'flex',
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'datePublication',
        headerName: 'Date Publication',
        display: 'flex',
        minWidth: 145,
        valueGetter: (value: string) => {
            const formattedDate = value ? format(value, 'dd-MM-yyyy HH:mm') : '';
            return formattedDate;
        },
    },
    {
        field: 'actions',
        headerName: '',
        display: 'flex',
        headerClassName: 'stickyColumnHeader',
        disableColumnMenu: true,
        sortable: false,
        cellClassName: 'stickyColumnCell',
        width: currentTab === 'active' ? 80 : 110,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%">
                    <Tooltip title={currentTab === 'active' ? 'Edit' : 'Preview'}>
                        <EditIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                onEdit(params.row);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={currentTab === 'active' ? 'Archive' : 'Restore'}>
                        {currentTab === 'active' ? (
                            <ClockIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onArchive(params.row);
                                }}
                            />
                        ) : (
                            <ArrowBackOutlinedIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onArchive(params.row);
                                }}
                            />
                        )}
                    </Tooltip>
                    {params.row.archived && params.row.canBeDeleted ? (
                        <Tooltip title="Delete">
                            <DeleteIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDelete(params.row);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </Box>
            );
        },
    },
];
