import { AxiosError } from 'axios';
import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { serializeFilters } from '../helpers/filters';
import { Collection, CollectionApplyDimensions, CollectionFormData } from '../types/collection';
import { BaseFilters } from '../types/table';
import { handleAxiosError } from '../helpers/axios';

export type GetCollectionsResponse = {
    collections: Collection[];
    totalResults: number;
};

export const getCollections = async (
    filters?: BaseFilters
): Promise<GetCollectionsResponse | void> => {
    let queryParams = filters ? serializeFilters(filters) : '';
    queryParams += '&type=adminpanel';

    const url = queryParams ? `/collections?${queryParams}` : '/collections';

    return await axiosVeerlInstance
        .get<GetCollectionsResponse>(url)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }

            return {
                collections: [],
                totalResults: 0,
            };
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getCollection = async (id: number): Promise<Collection | undefined | void> => {
    return await axiosVeerlInstance
        .get<Collection>(`/collection/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const createCollection = async (
    collectionFormData: CollectionFormData
): Promise<Collection | undefined | void> => {
    return await axiosVeerlInstance
        .post<Collection>(`/collection`, collectionFormData)
        .then((response) => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const updateCollection = async (
    collectionFormData: CollectionFormData,
    id: number
): Promise<Collection | undefined | void> => {
    return await axiosVeerlInstance
        .put<Collection>(`/collection/` + id, collectionFormData)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const updateApplyDimensionsCollection = async (
    collectionFormData: CollectionApplyDimensions,
    id: number
): Promise<Collection | undefined | void> => {
    return await axiosVeerlInstance
        .put<Collection>(`/collection/` + id + '?applyToModels=true', collectionFormData)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const deleteCollection = async (id: number) => {
    return await axiosVeerlInstance.delete(`/collection/${id}`).catch((error: AxiosError) => {
        handleAxiosError(error as AxiosError);
    });
};
