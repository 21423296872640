import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as ModelsIcon } from '../../assets/icons/models_outlined.svg';

const ModelUploadBox = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            component="div"
            sx={{
                width: '100%',
                maxWidth: '630px',
                height: isSmallScreen ? '266px' : '500px',
                padding: '12px',
                background: theme.palette.backgroundLighter,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
            <ModelsIcon />
            <Typography variant="p1" margin="8px 0 16px 0">
                3D model
            </Typography>
            <Typography
                variant="p1"
                marginTop="-12px"
                color={theme.palette.black54}
                textAlign="center">
                The model will be displayed after uploading
            </Typography>
        </Box>
    );
};

export default ModelUploadBox;
