import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useField } from 'formik';
import FormikTextField from '../../Formik/FormikTextField';
import Link from '../../Link/Link';
import { useEffect, useState } from 'react';
import DiscountDrawer from '../DiscountDrawer/DiscountDrawer';
import { ModelDiscount } from '../../../types/model';
import { format, parseISO } from 'date-fns';

type ModelPriceProps = {
    disabled?: boolean;
};

const ModelPrice = ({ disabled }: ModelPriceProps) => {
    const theme = useTheme();

    const [priceField, , priceHelpers] = useField<number | null>('price');
    const [discountField, , discountHelpers] = useField<ModelDiscount>('discount');
    const [isFreeForGuestsField, , isFreeForGuestsHelpers] = useField<boolean>('isFreeForGuests');
    const [isFreeForUsersField, , isFreeForUsersHelpers] = useField<boolean>('isFreeForUsers');

    const [isDiscountDrawerOpen, setIsDiscountDrawerOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isFreeForGuestsField.value === true || isFreeForUsersField.value === true) {
            priceHelpers.setValue(null);
            discountHelpers.setValue({
                active: false,
                value: null,
                dateStart: '',
                dateEnd: '',
            });
        }
    }, [
        isFreeForGuestsField.value,
        isFreeForUsersField.value,
        isFreeForGuestsHelpers,
        isFreeForUsersHelpers,
        discountHelpers,
        priceHelpers,
    ]);

    const price = priceField.value;
    const discount = discountField.value;

    const onDiscountSave = (discount: ModelDiscount) => {
        discountHelpers.setValue(discount);
        setIsDiscountDrawerOpen(false);
    };

    const parseDateFormat = () => {
        const dateStart = discount.dateStart;
        const dateEnd = discount.dateEnd;

        if (!dateStart || !dateEnd) return '';

        const startDate = parseISO(dateStart);
        const endDate = parseISO(dateEnd);

        const formattedDateStart = format(startDate, 'MM/dd/yyyy, hh:mm a');
        const formattedDateEnd = format(endDate, 'MM/dd/yyyy, hh:mm a');

        return `${formattedDateStart} - ${formattedDateEnd}`;
    };

    return (
        <Grid container gap="24px">
            <Grid item xs={12}>
                <FormikTextField
                    fullWidth
                    disabled={disabled}
                    label="Price ($)"
                    type="number"
                    name="price"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container gap="12px">
                    <Grid item xs={12}>
                        {!discount || !discount.active ? (
                            <Link
                                onClick={() => setIsDiscountDrawerOpen(true)}
                                label="Set discount"
                                disabled={!price || disabled}
                            />
                        ) : (
                            <Box
                                component="div"
                                display="flex"
                                width="100%"
                                justifyContent="space-between">
                                <Typography variant="p2">Discount active</Typography>
                                {!disabled ? (
                                    <Link
                                        onClick={() => setIsDiscountDrawerOpen(true)}
                                        label="Edit"
                                    />
                                ) : null}
                            </Box>
                        )}
                    </Grid>
                    {discount?.active ? (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    variant="p2SemiBold"
                                    color={theme.palette.confirmationGreen}>
                                    -{discount.value}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="p2" color={theme.palette.black54}>
                                    {parseDateFormat()}
                                </Typography>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </Grid>
            <DiscountDrawer
                onClose={() => setIsDiscountDrawerOpen(false)}
                onSave={onDiscountSave}
                isOpen={isDiscountDrawerOpen}
                discount={discount}
                disabled={disabled}
            />
        </Grid>
    );
};

export default ModelPrice;
