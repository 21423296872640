import { AxiosError } from 'axios';
import { APIError } from '../axios/axiosVeerlInstance';

export const handleAxiosError = (error: AxiosError): never => {
    console.log(error)
    if (error.response && error.response.data) {
        const responseData = error.response.data as { message: string };
        throw {
            message: responseData?.message || 'An unexpected error occurred.',
            status: error.response.status,
        } as APIError;
    } else {
        throw { message: 'An unexpected error occurred.', status: 500 };
    }
};
