import { Grid, Typography, useTheme } from '@mui/material';
import FormikTextField from '../../Formik/FormikTextField';

type DimensionRangeProps = {
    label: string;
    name: string;
};

const DimensionRange = ({ label, name }: DimensionRangeProps) => {
    const theme = useTheme();

    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" gap="4px">
                <Grid item xs>
                    <FormikTextField
                        fullWidth
                        label={`${label} from`}
                        type="number"
                        name={`${name}.from`}
                        variant="outlined"
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: 300,
                            },
                        }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="p1" color={theme.palette.black54}>
                        -
                    </Typography>
                </Grid>
                <Grid item xs>
                    <FormikTextField
                        fullWidth
                        label={`${label} to`}
                        type="number"
                        name={`${name}.to`}
                        variant="outlined"
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: 300,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DimensionRange;
