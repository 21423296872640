import { getUser } from '../api/user';
import { useQuery } from 'react-query';
import Layout from '../components/Layout/Layout';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ROUTES from '../router/routes';
import { AppRoute } from '../types/routes';
import { Toast } from '../components/Toast/Toast';
import SignInView from './Auth/SignInView';
import { useEffect } from 'react';
import { Box } from '@mui/material';

const RouteWrapper = () => {
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken');

    useEffect(() => {
        if (!authToken) navigate('/auth/sign-in');
    }, []);

    const { data: userData } = useQuery('user', getUser, {
        onError: () => {
            localStorage.removeItem('authToken');
            navigate('/auth/sign-in');
        },
        enabled: !!authToken,
    });

    const isAuthorized = userData?.id;

    return (
        <>
            {isAuthorized ? (
                <Layout>
                    <Routes>
                        {ROUTES.map((route: AppRoute) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={route.element}
                                index={route?.index}
                            />
                        ))}
                    </Routes>
                    <Toast />
                </Layout>
            ) : (
                <Box component="div" sx={{ flexGrow: 1, display: 'flex' }}>
                    <Routes>
                        <Route
                            key={'/auth/sign-in'}
                            path="/auth/sign-in"
                            element={<SignInView />}
                        />
                    </Routes>
                    <Toast />
                </Box>
            )}
        </>
    );
};

export default RouteWrapper;
