import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { useField } from 'formik';

const StyledSelect = styled(Select)(({ theme, error }) => ({
    '& .MuiInputBase-input': {
        backgroundColor: theme.palette.backgroundLighter,
        border: `1px solid ${error ? theme.palette.alert : theme.palette.borders}`,
        borderRadius: '4px',
    },
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '& .MuiSelect-select': {
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
    '& .Mui-selected': {
        backgroundColor: theme.palette.backgroundLighter,
    },
}));

type FormikSelectProps = SelectProps & {
    name: string;
    valueKey?: string;
    labelKey?: string;
    items?: any;
    onChangeCallback?: (value: any) => void;
};

const FormikSelect = ({
    name,
    valueKey,
    onChangeCallback,
    labelKey,
    items,
    ...props
}: FormikSelectProps) => {
    const theme = useTheme();
    const [field, meta, helpers] = useField(name);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        if (onChangeCallback) {
            onChangeCallback(event.target.value);
        }

        helpers.setValue(event.target.value);
    };

    return (
        <FormControl
            variant="filled"
            fullWidth
            sx={{
                marginTop: '-2px',
            }}>
            <InputLabel>
                <Typography variant="p1" color={theme.palette.black54}>
                    {props.label}
                </Typography>
            </InputLabel>
            <StyledSelect
                {...field}
                {...props}
                sx={{
                    marginTop: '2px',
                }}
                variant="filled"
                disableUnderline
                onChange={handleChange}
                size="small"
                value={field.value === null ? '' : field.value}
                error={Boolean(meta.error)}
                MenuProps={{
                    MenuListProps: {
                        style: {
                            padding: 0,
                        },
                    },
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                '&:hover': {
                                    backgroundColor: theme.palette.backgroundLighter,
                                },
                            },
                        },
                    },
                }}>
                {items?.map((item: any, key: number) => (
                    <MenuItem
                        value={valueKey ? item[valueKey] : item}
                        key={item}
                        sx={{
                            height: '48px',
                            background:
                                item === field.value || (key === 0 && !field.value)
                                    ? `${theme.palette.backgroundLighter} !important`
                                    : 'transparent',
                        }}>
                        {labelKey
                            ? item[labelKey]
                            : item[0].toUpperCase() + item.slice(1).toLowerCase()}
                    </MenuItem>
                ))}
            </StyledSelect>
            {meta.error ? (
                <Typography color="error" fontSize="0.75rem" margin="3px 11px 0 11px">
                    {meta.error}
                </Typography>
            ) : null}
        </FormControl>
    );
};

export default FormikSelect;
