import { FormikErrors } from 'formik';
import yup from './yup';

const scrollToFirstError = <T>(errors: FormikErrors<T>, isModal?: boolean) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
        const firstErrorKey = errorKeys[0];
        let rootElement = null;
        if (isModal) rootElement = document.getElementsByClassName('MuiDrawer-modal')[0];
        else rootElement = document.getElementById('root');

        if (rootElement) {
            const errorElementNameAttribute = rootElement.querySelector(
                `[name="${firstErrorKey}"]`
            );
            const errorElementDataNameAttribute = rootElement.querySelector(
                `[data-name="${firstErrorKey}"]`
            );

            if (errorElementNameAttribute) {
                errorElementNameAttribute.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (errorElementDataNameAttribute) {
                errorElementDataNameAttribute.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }
};

export const customValidate = async <T>(
    values: T,
    modelValidationSchema: yup.Schema<T> | yup.ObjectSchema<any>,
    isModal?: boolean
): Promise<FormikErrors<T>> => {
    try {
        await modelValidationSchema.validate(values, { abortEarly: false });
        return {};
    } catch (err) {
        let errors: FormikErrors<T> = {};
        if (err instanceof yup.ValidationError && err.inner) {
            err.inner.forEach((validationError) => {
                if (validationError.path) {
                    const path = validationError.path as keyof T;
                    errors[path] = validationError.message as any;
                }
            });
        }
        scrollToFirstError(errors, isModal);
        console.log(errors)
        return errors;
    }
};
