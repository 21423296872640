import { useField, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';
import Autocomplete, {
    createFilterOptions,
    AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '24px',
    backgroundColor: theme.palette.white,
    border: 'none',
    maxHeight: '24px',
    '& .MuiChip-deleteIcon': {
        width: '15px',
        height: '15px',
        fill: theme.palette.black54,
    },
}));

type OptionType = {
    label: string;
    value: string;
};

const filter = createFilterOptions<OptionType>();

type FormikAutocompleteProps = {
    name: string;
    options: string[];
    label?: string;
    creatable?: boolean;
    disabled?: boolean;
    onFirstElementAdded?: (value: string) => void;
};

const FormikAutocomplete = ({
    name,
    options,
    label,
    disabled,
    onFirstElementAdded,
    creatable = true,
}: FormikAutocompleteProps) => {
    const theme = useTheme();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<string[]>(name);

    const handleChange = (
        event: React.ChangeEvent<{}>,
        newValue: (string | OptionType)[],
        reason: string
    ) => {
        if (
            newValue.some((item) => typeof item === 'string' && !options.includes(item)) &&
            !creatable
        ) {
            return;
        }

        const valueToSet = newValue.map((item) => (typeof item === 'string' ? item : item.value));
        const firstElementAdded =
            (!field.value || field.value?.length === 0) && valueToSet.length === 1;

        setFieldValue(name, valueToSet).then(() => {
            if (firstElementAdded && onFirstElementAdded) {
                onFirstElementAdded(valueToSet[0])
            }
        });
    };

    const isError = Boolean(meta.error);

    return (
        <>
            <Autocomplete
                size="small"
                disabled={disabled}
                sx={{
                    border: `1px solid ${isError ? theme.palette.alert : theme.palette.borders}`,
                    borderRadius: '4px',
                    background: 'transparent',

                    '& .MuiButtonBase-root': {
                        margin: '12px 0',
                        marginLeft: '4px',
                    },

                    '& .MuiFilledInput-underline:after': {
                        border: 'none',
                    },
                    '& .MuiFilledInput-underline:before': {
                        borderBottom: 'none',
                    },
                    '& .MuiFilledInput-underline:hover:before': {
                        borderBottom: 'none',
                    },
                    '& .MuiAutocomplete-option': {
                        minHeight: '48px',
                        padding: '14px 16px',
                        '&[data-focus="true"]': {
                            backgroundColor: theme.palette.backgroundLighter,
                        },
                        '&[aria-selected="true"]': {
                            backgroundColor: theme.palette.backgroundLighter,
                        },
                    },
                }}
                multiple
                freeSolo
                id={name}
                ListboxProps={{
                    sx: {
                        padding: 0,
                    },
                }}
                options={options.map((option) => ({ label: option, value: option }))}
                value={field.value?.map((value) => ({ label: value, value })) || []}
                onChange={handleChange}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const selectedOptionValues = field.value || [];
                    const filteredExcludingSelected = filtered.filter(
                        (option) => !selectedOptionValues.includes(option.value)
                    );

                    const { inputValue } = params;
                    if (
                        creatable &&
                        inputValue !== '' &&
                        !options
                            .concat(field.value?.map((value) => ({ label: value, value })) || [])
                            .some((option) => option.value === inputValue)
                    ) {
                        filteredExcludingSelected.push({
                            label: `Add "${inputValue}"`,
                            value: inputValue,
                        });
                    }

                    return filteredExcludingSelected;
                }}
                getOptionLabel={(option) => {
                    return typeof option === 'string' ? option : option.label;
                }}
                renderOption={(props, option) => (
                    <li {...props}>{typeof option === 'string' ? option : option.label}</li>
                )}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                        {...params}
                        variant="filled"
                        error={isError}
                        name={name}
                        label={label || 'Select options'}
                    />
                )}
                renderTags={(value: readonly OptionType[], getTagProps) =>
                    value.map((option, index) => (
                        <StyledChip
                            variant="outlined"
                            label={option.label}
                            {...getTagProps({ index })}
                        />
                    ))
                }
            />
            {isError ? (
                <Typography color="error" fontSize="0.75rem" margin="3px 11px 0 11px">
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
};

export default FormikAutocomplete;
