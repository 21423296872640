import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { getConfig } from '../helpers/config';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../atoms/toastAtom';
import { handleAxiosError } from '../helpers/axios';

const axiosVeerlInstance = axios.create({
    baseURL: '',
    withCredentials: true,
});

axiosVeerlInstance.interceptors.request.use(async (axiosConfig) => {
    let config = await getConfig();
    axiosConfig.baseURL = config.veerlAPI;

    let authToken = localStorage.getItem('authToken');
    let cookieToken = Cookies.get('veerl-auth-token');

    if (authToken && axiosConfig && axiosConfig?.headers) {
        axiosConfig.headers.Authorization = 'Bearer ' + (cookieToken || authToken);
    }

    return axiosConfig;
});

export type APIError = {
    message: string;
    status: number;
};

export const useAxiosInterceptor = () => {
    const setToastState = useSetAtom(toastAtom);

    axiosVeerlInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (!error?.response) {
                if (window.location.pathname !== '/auth/sign-in')
                    window.location.href = '/auth/sign-in';

                setToastState({
                    message: 'Service is currently unavailable.',
                    severity: 'error',
                });
                return Promise.reject(error);
            }

            if (error.response.status === 401 || error.response.status === 403) {
                if (window.location.pathname !== '/auth/sign-in')
                    window.location.href = '/auth/sign-in';
            }

            const message =
                error?.response && error.response.status === 400
                    ? error.response.data.message
                    : error.response.status === 401 || error.response.status === 403
                      ? ''
                      : (error.response && error.response.status !== 404) || !error.response
                        ? 'Service is currently unavailable.'
                        : '';

            if (message)
                setToastState({
                    message: message,
                    severity: 'error',
                });

            return Promise.reject(error);
        }
    );
};

export default axiosVeerlInstance;
