import FormikAutocomplete from '../../Formik/FormikAutocomplete';
import { useFormikContext } from 'formik';
import { ModelFormData } from '../../../types/model';
import { Collection } from '../../../types/collection';

type ModelCollectionPickerProps = {
    collections: Collection[];
    isPreviewMode?: boolean;
};

const ModelCollectionPicker = ({ collections, isPreviewMode }: ModelCollectionPickerProps) => {
    const { values, setFieldValue } = useFormikContext<ModelFormData>();

    const updateFieldValues = (collectionAdded: string) => {
        const foundCollection = collections.find((x) => x.name === collectionAdded);

        if (values.dimensions.breast === null || values.dimensions.breast === '') {
            setFieldValue('dimensions.breast', foundCollection?.dimensions?.breast || null);
        }
        if (values.dimensions.height === null || values.dimensions.height === '') {
            setFieldValue('dimensions.height', foundCollection?.dimensions?.height || null);
        }
        if (values.dimensions.hips === null || values.dimensions.hips === '') {
            setFieldValue('dimensions.hips', foundCollection?.dimensions?.hips || null);
        }
        if (values.dimensions.waist === null || values.dimensions.waist === '') {
            setFieldValue('dimensions.waist', foundCollection?.dimensions?.waist || null);
        }
        if (values.attributes.breast === '') {
            setFieldValue('attributes.breast', foundCollection?.attributes?.breast || null);
        }
        if (values.attributes.hair === '') {
            setFieldValue('attributes.hair', foundCollection?.attributes?.hair || '');
        }
        if (values.attributes.race === '') {
            setFieldValue('attributes.race', foundCollection?.attributes?.race || '');
        }
    };

    return (
        <FormikAutocomplete
            creatable={false}
            options={collections.map((x) => x.name) || []}
            onFirstElementAdded={updateFieldValues}
            name="collectionsIds"
            label="Collection"
            disabled={isPreviewMode}
        />
    );
};

export default ModelCollectionPicker;
