import { format, isToday, isYesterday } from 'date-fns';

const parseDateFromToday = (passedDate: string) => {
    const date = new Date(passedDate);

    let formattedDate;
    if (isToday(date)) {
        formattedDate = `Today ${format(date, 'HH:mm')}`;
    } else if (isYesterday(date)) {
        formattedDate = `Yesterday ${format(date, 'HH:mm')}`;
    } else {
        formattedDate = format(date, 'dd-MM-yyyy HH:mm');
    }

    return formattedDate;
};

export default parseDateFromToday;
