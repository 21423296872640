import { Box } from '@mui/material';
import UserAbout from '../Sections/UserAbout';
import DeleteUser from '../Sections/DeleteUser';
import { User } from '../../../types/user';

type PersonalDataProps = {
    userData: User;
};

const PersonalData = ({ userData }: PersonalDataProps) => {
    return (
        <Box
            component="div"
            maxWidth="920px"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '64px',
            }}>
            <UserAbout userData={userData} />
            <DeleteUser userData={userData} />
        </Box>
    );
};

export default PersonalData;
