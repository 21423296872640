import yup from '../helpers/yup';

// export type AddAssetFormData = {
//     userID: string;
//     reference: {
//         modelID: string;
//     };
// };

export const NewAssetValidationSchema = yup.object({
    reference: yup.object({
        modelID: yup.string().required('Asset is required'),
    }),
    assetType: yup.string().optional(),
});
