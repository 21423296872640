import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { Model, ModelFormData, ModelsFilters } from '../types/model';
import { serializeFilters } from '../helpers/filters';
import { AxiosError } from 'axios';
import { handleAxiosError } from '../helpers/axios';

type GetModelIDResponse = {
    id: string;
};

export type GetModelModelAttributesResponse = {
    race: string[];
    hair: string[];
    breast: string[];
};

export const getModelID = async () => {
    return await axiosVeerlInstance
        .get<GetModelIDResponse>(`/model/id`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getModelAttributes = async () => {
    return await axiosVeerlInstance
        .get<GetModelModelAttributesResponse>(`/model/attributes`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getModelRarities = async () => {
    return await axiosVeerlInstance
        .get<string[]>(`/model/rarities`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const createModel = async (model: ModelFormData) => {
    return await axiosVeerlInstance
        .post<Model>(`/model`, model)
        .then((response) => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const updateModel = async (model: ModelFormData) => {
    return await axiosVeerlInstance
        .put<Model>(`/model/` + model.id, model)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export type GetModelsResponse = {
    models: Model[];
    totalResults: number;
};

export const getModels = async (filters?: ModelsFilters) => {
    let queryParams = filters ? serializeFilters(filters) : '';

    queryParams += '&type=adminpanel';

    return await axiosVeerlInstance
        .get<GetModelsResponse>(`/models?${queryParams}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }

            return {
                models: [],
                totalResults: 0,
            };
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getModel = async (modelID?: string) => {
    return await axiosVeerlInstance
        .get<Model>(`/model/${modelID}?type=adminpanel`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const archiveModel = async (modelID: string) => {
    return await axiosVeerlInstance.post(`/model/${modelID}/archive`).catch((error: AxiosError) => {
        handleAxiosError(error as AxiosError);
    });
};

export const retoreModel = async (modelID: string) => {
    return await axiosVeerlInstance
        .post(`/model/${modelID}/unarchive`)
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const deleteModel = async (modelID: string) => {
    return await axiosVeerlInstance.delete(`/model/${modelID}`).catch((error: AxiosError) => {
        handleAxiosError(error as AxiosError);
    });
};

export const archiveModels = async (modelIds: string[], archive: boolean) => {
    return await axiosVeerlInstance
        .post(`/models/${archive ? '/archive' : '/unarchive'}`, { ids: modelIds })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const publishModels = async (modelIds: string[], publish: boolean) => {
    return await axiosVeerlInstance
        .post(`/models/${publish ? '/publish' : '/unpublish'}`, { ids: modelIds })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getDescriptionTags = async () => {
    return await axiosVeerlInstance
        .get<string[]>(`/model/description-tags`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const getPoseTags = async () => {
    return await axiosVeerlInstance
        .get<string[]>(`/model/poses`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};
