import { ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';

import { AppRoute } from '../../../types/routes';
import ROUTES from '../../../router/routes';

type SideMenuItemProps = {
    menuItem: AppRoute;
    index: number;
    currentSection?: AppRoute;
    navigateToRouting: (path: string) => void;
};

const SideMenuItem = ({
    menuItem,
    index,
    currentSection,
    navigateToRouting,
}: SideMenuItemProps) => {
    const theme = useTheme();
    const isCurrentSection =
        (currentSection?.path === '/' && menuItem.path === '/') ||
        (menuItem?.path !== '/' && currentSection?.path.includes(menuItem.path));

    return (
        <ListItemButton
            key={menuItem.title}
            onClick={() => navigateToRouting(menuItem.path)}
            sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                marginTop:
                    index > 0 && index < ROUTES.filter((route: AppRoute) => route.isMenu).length
                        ? '12px'
                        : '0px',
                height: '48px',
                gap: '20px',
                alignItems: 'center',
            }}>
            <ListItemIcon
                sx={{
                    minWidth: 'auto',
                    '& svg': {
                        fill: isCurrentSection ? theme.palette.accentVividBlue : 'default',
                    },
                    '& path': {
                        stroke: isCurrentSection ? theme.palette.white : 'default',
                    },
                }}>
                {menuItem.icon}
            </ListItemIcon>
            <ListItemText
                primary={menuItem.title}
                primaryTypographyProps={{
                    variant: 'p2',
                    color: menuItem === currentSection ? theme.palette.accentVividBlue : 'inherit',
                }}
                sx={{
                    margin: 0,
                }}
            />
        </ListItemButton>
    );
};

export default SideMenuItem;
