import { useEffect, useState } from 'react';
import { calculateRowHeightAndPageSize } from '../../../helpers/datagrid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Grid, Typography } from '@mui/material';
import CustomDataGrid from '../../../components/CustomDataGrid/CustomDataGrid';
import { deleteUserAsset, getUserAssets } from '../../../api/user';
import { USER_ASSETS_COLUMNS } from '../../../const/userAssetsColumns';
import AddAssetDrawer from '../../../components/User/AddAssetDrawer';
import { Asset } from '../../../types/assets';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../../atoms/toastAtom';
import { AxiosError } from 'axios';

type UserAssetsProps = {
    userID: string;
    userName: string;
    triggerAction?: boolean;
};

const UserAssets = ({ userID, userName, triggerAction }: UserAssetsProps) => {
    const queryClient = useQueryClient();
    const setToastState = useSetAtom(toastAtom);
    const rowProperties = calculateRowHeightAndPageSize(320, true);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<Asset | null>();
    const [showAddAssetDrawer, setShowAddAssetDrawer] = useState<boolean>(false);

    const { data: userAssets, isLoading } = useQuery(['userAssets', userID], () =>
        getUserAssets(userID)
    );

    const { mutateAsync: mutateRemoveAsset, isLoading: isRemovingAsset } = useMutation(
        ['user', userID, 'asset', showConfirmDeleteDialog?.id],
        () => deleteUserAsset(userID, showConfirmDeleteDialog?.id!!),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['userAssets', userID]);
                setToastState({
                    message: 'Asset removed successfully!',
                    severity: 'success',
                });
            },
            onError: (error: AxiosError) => {
                if (error?.message) {
                    setToastState({
                        message: error.message,
                        severity: 'error',
                    });
                }
            },
        }
    );

    useEffect(() => {
        if (triggerAction !== undefined) setShowAddAssetDrawer(true);
    }, [triggerAction]);

    const onRemoveAssetHandler = async () => {
        try {
            await mutateRemoveAsset();
        } catch (err) {}

        setShowConfirmDeleteDialog(null);
    };

    const userModels = userAssets?.oneTime?.models || [];
    const sortedUserModels = [...userModels].sort((a, b) => {
        return new Date(b.dateAdd).getTime() - new Date(a.dateAdd).getTime();
    });

    return (
        <Grid
            container
            gap="16px"
            sx={{
                padding: '0px 20px 16px 20px',
            }}>
            <Grid item xs={12}>
                <CustomDataGrid
                    tableName="userAssets"
                    rowProperties={rowProperties}
                    height="calc(100vh - 310px)"
                    disableColumnFilter
                    rows={sortedUserModels}
                    columns={USER_ASSETS_COLUMNS({
                        onDelete: (asset) => setShowConfirmDeleteDialog(asset),
                    })}
                    dataProcess="client"
                    loading={isLoading}
                    localeText={{
                        noRowsLabel: 'No assets found.',
                        noResultsOverlayLabel: 'No assets found.',
                    }}
                />
            </Grid>
            <AddAssetDrawer
                userAssets={sortedUserModels}
                userID={userID}
                isOpen={showAddAssetDrawer}
                onClose={() => setShowAddAssetDrawer(false)}
            />
            <CustomDialog
                open={!!showConfirmDeleteDialog}
                onClose={() => setShowConfirmDeleteDialog(null)}
                onConfirm={onRemoveAssetHandler}
                title="Remove asset from user"
                confirmLabel="Remove"
                isLoading={isRemovingAsset}
                variant="danger"
                content={
                    <Typography variant="p2" textAlign="center">
                        Are you sure that you want to remove{' '}
                        <b>
                            {showConfirmDeleteDialog?.reference?.collection?.name ||
                                showConfirmDeleteDialog?.reference?.model?.name}
                        </b>{' '}
                        from <b>{userName}</b> assets?
                    </Typography>
                }
            />
        </Grid>
    );
};

export default UserAssets;
