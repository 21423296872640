import { useMemo, useRef, useState } from 'react';
import { BASE_PAGE_SIZE_OPTIONS, calculateRowHeightAndPageSize } from '../../../helpers/datagrid';
import { useQuery } from 'react-query';
import { getPaymentHistory } from '../../../api/payments';
import { Grid, useTheme } from '@mui/material';
import CustomDataGrid from '../../../components/CustomDataGrid/CustomDataGrid';
import { PAYMENT_HISTORY_TABLE_COLUMNS } from '../../../const/paymentHistoryColumns';
import { PaymentHistoryFilters } from '../../../types/payments';

type PaymentHistoryProps = {
    userID?: string;
};

const PaymentHistory = ({ userID }: PaymentHistoryProps) => {
    const theme = useTheme();

    const rowProperties = calculateRowHeightAndPageSize(325, true);
    const savedPageSize = localStorage.getItem('paymentHistory.pageSize');

    const [filters, setFilters] = useState<PaymentHistoryFilters>({
        search: '',
        order: {
            user: {
                id: userID,
            },
        },
        pageSize:
            savedPageSize &&
            +savedPageSize &&
            BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize).includes(+savedPageSize)
                ? +savedPageSize
                : rowProperties.pageSize,
        page: 1,
    });

    const { data: paymentHistory, isLoading } = useQuery(['paymentHistory', userID, filters], () =>
        getPaymentHistory(filters)
    );

    const onDownloadInvoiceHandler = (paymentID: string, url: string) => {
        const filename = 'Veerl-Invoice-' + paymentID + '.pdf';
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const paymentOrders = paymentHistory?.history || [];

    const rowCountRef = useRef(paymentHistory?.totalResults || 0);

    const rowCount = useMemo(() => {
        if (paymentHistory?.totalResults !== undefined) {
            rowCountRef.current = paymentHistory.totalResults;
        }
        return rowCountRef.current;
    }, [paymentHistory?.totalResults]);

    return (
        <Grid
            container
            gap="16px"
            sx={{
                padding: '0px 20px 16px 20px',
            }}>
            <Grid item xs={12}>
                <CustomDataGrid
                    tableName="paymentHistory"
                    rowProperties={rowProperties}
                    height="calc(100vh - 310px)"
                    disableColumnFilter
                    rows={paymentOrders}
                    filters={filters}
                    onFiltersChange={(filters) => setFilters(filters as PaymentHistoryFilters)}
                    columns={PAYMENT_HISTORY_TABLE_COLUMNS({
                        theme: theme,
                        onDownloadInvoice: onDownloadInvoiceHandler,
                    })}
                    rowCount={rowCount}
                    loading={isLoading}
                    localeText={{
                        noRowsLabel: 'No orders found.',
                        noResultsOverlayLabel: 'No orders found.',
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default PaymentHistory;
