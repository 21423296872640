import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Formik } from 'formik';
import { Button, Box, Grid, useTheme, CircularProgress, Drawer, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import DrawerHeader from '../Drawer/DrawerHeader';
import DrawerContent from '../Drawer/DrawerContent';
import DrawerFooter from '../Drawer/DrawerFooter';
import { APIError } from '../../axios/axiosVeerlInstance';
import { AddAssetFormData, Asset } from '../../types/assets';
import { NewAssetValidationSchema } from '../../validation/assetSchema';
import FormikSelect from '../Formik/FormikSelect';
import { getModels } from '../../api/model';
import FormikAutocompleteSelect from '../Formik/FormikAutocompleteSelect';
import { addUserAsset } from '../../api/user';

type AddAssetDrawerProps = {
    userID: string;
    isOpen: boolean;
    onClose: () => void;
    userAssets: Asset[];
};

const AddAssetDrawer = ({ isOpen, userID, userAssets, onClose }: AddAssetDrawerProps) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const setToastState = useSetAtom(toastAtom);

    const { data: modelsResponse } = useQuery(
        [
            'models',
            {
                search: '',
                page: 0,
                pageSize: -1,
                archived: 'false',
                collection: {
                    id: [],
                },
            },
        ],
        () =>
            getModels({
                search: '',
                page: 0,
                pageSize: -1,
                archived: 'false',
                collection: {
                    id: [],
                },
            })
    );

    const { mutateAsync: mutateAddAsset, isLoading: isCreatingCollection } = useMutation(
        'addAsset',
        (data: AddAssetFormData) => addUserAsset(userID, data),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['userAssets', userID]);
                onClose();
                setToastState({
                    message: 'Asset added successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const onSubmitHandler = async (values: AddAssetFormData) => {
        try {
            await mutateAddAsset(values);
        } catch (err) {}
    };

    const models = modelsResponse?.models || [];
    const filteredModels = models.filter(
        (model) => !userAssets.find((asset) => asset.reference?.model?.id === model.id)
    );

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <DrawerHeader
                onClose={onClose}
                label={
                    <Box component="div" display="flex" alignItems="center" gap="16px">
                        <Typography variant="p1SemiBold">Add Asset</Typography>
                    </Box>
                }
            />
            <DrawerContent>
                <Formik
                    enableReinitialize
                    initialValues={{
                        assetType: 'model',
                        reference: {
                            modelID: '',
                        },
                    }}
                    onSubmit={onSubmitHandler}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={NewAssetValidationSchema}>
                    {({ submitForm }) => (
                        <Grid
                            item
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <Box
                                component="div"
                                sx={{
                                    borderRight: `1px solid ${theme.palette.borders}`,
                                    width: '430px',
                                    padding: '0px 24px 20px 20px',
                                }}>
                                <Grid
                                    item
                                    xs
                                    style={{
                                        paddingBottom: 0,
                                    }}>
                                    <Grid
                                        container
                                        sx={{
                                            '& > .MuiGrid-item': {
                                                padding: '16px 0',
                                                borderBottom: '1px solid ' + theme.palette.borders,
                                            },
                                            '& > .MuiGrid-item:first-child': {
                                                paddingTop: 0,
                                            },
                                            '& > .MuiGrid-item:last-child': {
                                                borderBottom: 'none',
                                                paddingBottom: 0,
                                            },
                                        }}>
                                        <Grid item xs={12}>
                                            <Grid container spacing="16px">
                                                <Grid item xs={12}>
                                                    <FormikSelect
                                                        variant="filled"
                                                        label="Asset type"
                                                        value="model"
                                                        name="assetType"
                                                        fullWidth
                                                        items={['model']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikAutocompleteSelect
                                                        label="Asset"
                                                        name="reference.modelID"
                                                        fullWidth
                                                        items={filteredModels.map((model) => ({
                                                            id: model.id,
                                                            name: model.name,
                                                            render: (
                                                                <Box
                                                                    component="div"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    gap="16px">
                                                                    <Box
                                                                        component="div"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        style={{
                                                                            width: '64px',
                                                                            height: '64px',
                                                                            minWidth: '64px',
                                                                            minHeight: '64px',
                                                                            borderRadius: '8px',
                                                                            backgroundImage: `url(${model.thumbnail})`,
                                                                            backgroundSize:
                                                                                'contain',
                                                                            backgroundRepeat:
                                                                                'no-repeat',
                                                                            backgroundPosition:
                                                                                'center',
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {model.name}
                                                                    </Typography>
                                                                </Box>
                                                            ),
                                                        }))}
                                                        valueKey="id"
                                                        labelKey="name"
                                                        renderKey="render"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <DrawerFooter>
                                <Button
                                    disabled={isCreatingCollection}
                                    variant="contained"
                                    color="primary"
                                    onClick={submitForm}>
                                    {!isCreatingCollection ? 'Add' : <CircularProgress size={20} />}
                                </Button>
                            </DrawerFooter>
                        </Grid>
                    )}
                </Formik>
            </DrawerContent>
        </Drawer>
    );
};

export default AddAssetDrawer;
