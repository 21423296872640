import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Box, Tooltip, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/x.svg';
import CustomChip from '../components/CustomChip/CustomChip';
import { Asset } from '../types/assets';

type UserSubscriptionsColumnsCallbacks = {
    onDelete: (subscription: Asset) => void;
    onCancel: (subscription: Asset) => void;
};

export const USER_SUBSCRIPTIONS_COLUMNS = ({
    onDelete,
    onCancel,
}: UserSubscriptionsColumnsCallbacks): GridColDef<Asset>[] => [
    {
        field: 'model.name',
        headerName: 'Asset name',
        display: 'flex',
        minWidth: 220,
        flex: 1,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={
                        params.row.reference?.tier
                            ? 'Tier ' + params.row.reference.tier
                            : params.row.reference?.collection?.name ||
                              params.row.reference?.model?.name
                    }>
                    <Typography variant="p2">
                        {params.row.reference?.tier
                            ? 'Tier ' + params.row.reference.tier
                            : params.row.reference?.collection?.name ||
                              params.row.reference?.model?.name}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'type',
        headerName: 'Subscription type',
        display: 'flex',
        minWidth: 220,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title="Model">
                    <Typography variant="p2">
                        {params.row.reference?.tier
                            ? 'Tier'
                            : params.row.reference?.model
                              ? 'Model'
                              : 'Collection'}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'subscription.dateAdd',
        headerName: 'Date start',
        display: 'flex',
        width: 220,
        valueGetter: (row: Asset) => {
            const formattedDate = row?.subscription?.dateAdd
                ? format(row.subscription.dateAdd, 'dd-MM-yyyy')
                : '';
            return formattedDate;
        },
    },
    {
        field: 'subscription.dateNextCycle',
        headerName: 'Next payment',
        display: 'flex',
        width: 220,
        valueGetter: (row: Asset) => {
            const formattedDate = row?.subscription?.dateAdd
                ? format(row.subscription.dateNextCycle, 'dd-MM-yyyy')
                : '';
            return formattedDate;
        },
    },
    {
        field: 'subscription.status',
        headerName: 'Canceled',
        display: 'flex',
        width: 160,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.row.subscription?.status === 'canceled' ? 'Yes' : 'No'}
                    variant={
                        params.row.subscription?.status === 'canceled'
                            ? 'primary'
                            : 'primaryOutlined'
                    }
                />
            );
        },
    },
    {
        field: 'order.amount',
        headerName: 'Amount',
        display: 'flex',
        minWidth: 120,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={
                        params.row?.order?.amount
                            ? params.row?.order?.amount + ' ' + params.row?.order?.currency
                            : '-'
                    }>
                    <Typography variant="p2">
                        {params.row?.order?.amount
                            ? params.row?.order?.amount + ' ' + params.row?.order?.currency
                            : '-'}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'actions',
        headerName: '',
        display: 'flex',
        headerClassName: 'stickyColumnHeader',
        disableColumnMenu: true,
        sortable: false,
        cellClassName: 'stickyColumnCell',
        width: 64,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    width="100%">
                    {params.row.subscription?.status !== 'canceled' ? (
                        <Tooltip title="Cancel subscription">
                            <CloseIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onCancel(params.row);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    <Tooltip title="Delete subscription">
                        <DeleteIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                onDelete(params.row);
                            }}
                        />
                    </Tooltip>
                </Box>
            );
        },
    },
];
