import { useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { OrbitControls, useBounds } from '@react-three/drei';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { useField } from 'formik';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

type ModelMeshProps = {
    name: string;
    modelUrl?: string;
    assets?: string[];
};

const ModelMesh = ({ name, modelUrl, assets = [] }: ModelMeshProps) => {
    const pivotRef = useRef(new THREE.Group());
    const mixerRef = useRef<THREE.AnimationMixer | null>(null);
    const isAddingModel = useRef<boolean>(false);

    const [, , assetsErrorsHelpers] = useField<string[]>(`${name}.assetsErrors`);

    const { scene, camera, gl } = useThree();
    const bounds = useBounds();

    useEffect(() => {
        if (isAddingModel.current) return;
        isAddingModel.current = true;

        pivotRef.current.clear();
        mixerRef.current = null;

        if (!modelUrl) return;

        scene.traverse((object) => {
            if (object instanceof THREE.Mesh) {
                object.visible = false;
            }
        });

        const extension = modelUrl.split('?')[0].split('.').pop()?.toLowerCase();
        if (extension) {
            loadModel(modelUrl, assets, extension);
        }
    }, [modelUrl, assets]);

    useFrame((state, delta) => {
        if (mixerRef.current) {
            mixerRef.current.update(delta);
        }
    });

    const loadAndApplyTextures = (assets: string[], object: THREE.Object3D) => {
        const textureLoader = new THREE.TextureLoader();

        const filteredAssets = assets.filter((asset) => {
            const extension = asset.split('.').pop()?.toLowerCase();
            return extension === 'jpg' || extension === 'jpeg' || extension === 'bmp';
        });

        filteredAssets.forEach((asset) => {
            const fileName = asset.split('/').pop()?.split('.').shift();
            object.traverse((child: THREE.Object3D) => {
                if (child instanceof THREE.Mesh) {
                    const meshMaterials = Array.isArray(child.material)
                        ? child.material
                        : [child.material];
                    const foundMaterial = meshMaterials.find(
                        (material) => material.name === fileName
                    );
                    if (foundMaterial) {
                        const texture = textureLoader.load(asset);
                        applyTextureToMaterial(foundMaterial, texture);
                    } else {
                        console.log('No material found for texture:', asset);
                        assetsErrorsHelpers.setValue([asset]);
                    }
                }
            });
        });

        return object;
    };

    const applyTextureToMaterial = (
        material: THREE.Material | THREE.Material[],
        texture: THREE.Texture
    ) => {
        if (material instanceof THREE.Material && 'map' in material) {
            material.map = texture;
            material.needsUpdate = true;
        } else if (Array.isArray(material)) {
            material.forEach((mat) => {
                if (mat instanceof THREE.Material && 'map' in mat) {
                    mat.map = texture;
                    mat.needsUpdate = true;
                }
            });
        }
    };

    const updateMatrixWorld = (object: THREE.Object3D) => {
        object.traverse((child: any) => {
            child.updateMatrixWorld(true);
        });
    };

    const loadModel = (url: string, assets: string[], extension: string) => {
        const basePath = url.substring(0, url.lastIndexOf('/') + 1);
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        const loadObjModel = (objUrl: string, mtlUrl?: string) => {
            const objLoader = new OBJLoader();
            if (mtlUrl) {
                const mtlLoader = new MTLLoader();
                mtlLoader.setPath(basePath);
                mtlLoader.load(mtlUrl, (materials) => {
                    materials.preload();
                    objLoader.setMaterials(materials);
                    objLoader.load(objUrl, (object) => {
                        updateMatrixWorld(object);
                        addObjectToScene(object, true);
                    });
                });
            } else {
                objLoader.load(objUrl, (object) => {
                    updateMatrixWorld(object);
                    addObjectToScene(object);
                });
            }
        };

        const loadGltfModel = (gltfUrl: string) => {
            const gltfLoader = new GLTFLoader();
            gltfLoader.setDRACOLoader(dracoLoader);
            gltfLoader.load(gltfUrl, (gltf) => {
                const animations = gltf.animations;
                if (animations && animations.length) {
                    mixerRef.current = new THREE.AnimationMixer(gltf.scene);
                    animations.forEach((clip) => {
                        const action = mixerRef.current?.clipAction(clip);
                        action?.play();
                    });
                }

                updateMatrixWorld(gltf.scene);
                addObjectToScene(gltf.scene);
            });
        };

        const addObjectToScene = (object: THREE.Object3D, hasMtlFile?: boolean) => {
            if (!hasMtlFile) loadAndApplyTextures(assets, object);

            const box = new THREE.Box3().setFromObject(object);
            const center = box.getCenter(new THREE.Vector3());
            object.position.sub(center);
            pivotRef.current.add(object);

            scene.add(pivotRef.current);
            bounds.refresh(object).clip().fit();

            isAddingModel.current = false;
        };

        switch (extension) {
            case 'obj':
                const mtlFile = (assets.find((asset) => asset.endsWith('.mtl')) || '')
                    .split('/')
                    .pop();
                loadObjModel(url, mtlFile);
                break;
            case 'gltf':
                loadGltfModel(url);
                break;
            case 'glb':
                loadGltfModel(url);
                break;
        }
    };

    return (
        <>
            <primitive object={pivotRef.current} />;
            <OrbitControls
                args={[camera, gl.domElement]}
                enableZoom={true}
                enablePan={false}
                target={pivotRef.current.position}
            />
        </>
    );
};

export default ModelMesh;
