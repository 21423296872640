import yup from '../helpers/yup';

export const TierSubscriptionsValidationSchema = yup.object().shape({
    tier1: yup.object().shape({
        price: yup.number().nullable(),
        thumbnail: yup.string().required('Thumbnail is required.'),
    }),
    tier2: yup.object().shape({
        price: yup.number().nullable(),
        thumbnail: yup.string().required('Thumbnail is required.'),
    }),
    tier3: yup.object().shape({
        price: yup.number().nullable(),
        thumbnail: yup.string().required('Thumbnail is required.'),
    }),
});

const today = new Date();
today.setHours(0, 0, 0, 0);

export const AddSubscriptionFormDataValidationSchema = yup.object().shape({
    dateNextCycle: yup
        .date()
        .min(today, 'Date should not be in the past.')
        .required('This field is required.'),
    reference: yup.object().shape({
        collectionID: yup.string().when('$subscriptionType', {
            is: 'collection',
            then: (schema) => schema.required('Collection is required.'),
            otherwise: (schema) => schema.nullable().notRequired(),
        }),
        tier: yup.number().when('$subscriptionType', {
            is: 'tier',
            then: (schema) => schema.required('Tier is required.'),
            otherwise: (schema) => schema.nullable().notRequired(),
        }),
    }),
});
