import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Formik } from 'formik';
import { Button, Box, Grid, useTheme, CircularProgress, Drawer, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import DrawerHeader from '../Drawer/DrawerHeader';
import DrawerContent from '../Drawer/DrawerContent';
import DrawerFooter from '../Drawer/DrawerFooter';
import { APIError } from '../../axios/axiosVeerlInstance';
import { Asset } from '../../types/assets';
import FormikSelect from '../Formik/FormikSelect';
import FormikAutocompleteSelect from '../Formik/FormikAutocompleteSelect';
import { addUserSubscription } from '../../api/user';
import { getCollections } from '../../api/collection';
import { AddSubscriptionFormData } from '../../types/subscription';
import { AddSubscriptionFormDataValidationSchema } from '../../validation/subscriptions';
import { TIER_OPTIONS } from '../../types/model';
import FormikTextField from '../Formik/FormikTextField';

type AddSubscriptionDrawerProps = {
    userID: string;
    isOpen: boolean;
    userAssets: Asset[];
    onClose: () => void;
};

const AddSubscriptionDrawer = ({
    isOpen,
    userID,
    userAssets,
    onClose,
}: AddSubscriptionDrawerProps) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const setToastState = useSetAtom(toastAtom);

    const { data: collectionsResponse } = useQuery('collections', () => getCollections());

    const { mutateAsync: mutateAddSubscription, isLoading: isCreatingCollection } = useMutation(
        'addSubscription',
        (data: AddSubscriptionFormData) => addUserSubscription(userID, data),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['userAssets', userID]);
                onClose();
                setToastState({
                    message: 'Subscription added successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const onSubmitHandler = async (values: AddSubscriptionFormData) => {
        if (values.dateNextCycle) {
            values.dateNextCycle = new Date(values.dateNextCycle).toISOString();
        }

        if (!values.reference.collectionID) delete values.reference.collectionID;
        if (!values.reference.tier) delete values.reference.tier;

        try {
            await mutateAddSubscription(values);
        } catch (err) {}
    };

    const collections =
        collectionsResponse?.collections.filter(
            (x) => !userAssets.find((asset) => asset.reference?.collection?.id === x.id)
        ) || [];

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <DrawerHeader
                onClose={onClose}
                label={
                    <Box component="div" display="flex" alignItems="center" gap="16px">
                        <Typography variant="p1SemiBold">Add Subscription</Typography>
                    </Box>
                }
            />
            <DrawerContent>
                <Formik
                    enableReinitialize
                    initialValues={{
                        subscriptionType: 'collection',
                        dateNextCycle: '',
                        reference: {
                            collectionID: null,
                            tier: null,
                        },
                    }}
                    onSubmit={onSubmitHandler}
                    validate={(values) => {
                        return AddSubscriptionFormDataValidationSchema.validate(values, {
                            abortEarly: false,
                            context: { subscriptionType: values.subscriptionType },
                        })
                            .then(() => {})
                            .catch(() => {});
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={AddSubscriptionFormDataValidationSchema}>
                    {({ submitForm, values, setFieldValue }) => {
                        return (
                            <Grid
                                item
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <Box
                                    component="div"
                                    sx={{
                                        borderRight: `1px solid ${theme.palette.borders}`,
                                        width: '430px',
                                        padding: '0px 24px 20px 20px',
                                    }}>
                                    <Grid
                                        item
                                        xs
                                        style={{
                                            paddingBottom: 0,
                                        }}>
                                        <Grid
                                            container
                                            sx={{
                                                '& > .MuiGrid-item': {
                                                    padding: '16px 0',
                                                    borderBottom:
                                                        '1px solid ' + theme.palette.borders,
                                                },
                                                '& > .MuiGrid-item:first-child': {
                                                    paddingTop: 0,
                                                },
                                                '& > .MuiGrid-item:last-child': {
                                                    borderBottom: 'none',
                                                    paddingBottom: 0,
                                                },
                                            }}>
                                            <Grid item xs={12}>
                                                <Grid container spacing="16px">
                                                    <Grid item xs={12}>
                                                        <FormikSelect
                                                            variant="filled"
                                                            label="Subscription type"
                                                            value="model"
                                                            name="subscriptionType"
                                                            fullWidth
                                                            items={['collection', 'tier']}
                                                            onChangeCallback={(value: string) => {
                                                                if (value === 'collection') {
                                                                    setFieldValue(
                                                                        'reference.tier',
                                                                        ''
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'reference.collectionID',
                                                                        ''
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikAutocompleteSelect
                                                            label="Susbcription asset"
                                                            name={
                                                                values.subscriptionType ===
                                                                'collection'
                                                                    ? 'reference.collectionID'
                                                                    : 'reference.tier'
                                                            }
                                                            fullWidth
                                                            items={
                                                                values.subscriptionType ===
                                                                'collection'
                                                                    ? collections.map(
                                                                          (collection) => ({
                                                                              id: collection.id,
                                                                              name: collection.name,
                                                                              render: (
                                                                                  <Box
                                                                                      component="div"
                                                                                      display="flex"
                                                                                      alignItems="center"
                                                                                      gap="16px">
                                                                                      <Box
                                                                                          component="div"
                                                                                          display="flex"
                                                                                          alignItems="center"
                                                                                          justifyContent="center"
                                                                                          style={{
                                                                                              width: '64px',
                                                                                              height: '64px',
                                                                                              minWidth:
                                                                                                  '64px',
                                                                                              minHeight:
                                                                                                  '64px',
                                                                                              borderRadius:
                                                                                                  '8px',
                                                                                              backgroundImage: `url(${collection.thumbnail})`,
                                                                                              backgroundSize:
                                                                                                  'contain',
                                                                                              backgroundRepeat:
                                                                                                  'no-repeat',
                                                                                              backgroundPosition:
                                                                                                  'center',
                                                                                          }}
                                                                                      />
                                                                                      <Typography>
                                                                                          {
                                                                                              collection.name
                                                                                          }
                                                                                      </Typography>
                                                                                  </Box>
                                                                              ),
                                                                          })
                                                                      )
                                                                    : TIER_OPTIONS
                                                            }
                                                            renderKey={
                                                                values.subscriptionType ===
                                                                'collection'
                                                                    ? 'render'
                                                                    : undefined
                                                            }
                                                            valueKey={
                                                                values.subscriptionType ===
                                                                'collection'
                                                                    ? 'id'
                                                                    : 'value'
                                                            }
                                                            labelKey={
                                                                values.subscriptionType ===
                                                                'collection'
                                                                    ? 'name'
                                                                    : 'label'
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            fullWidth
                                                            type="date"
                                                            name="dateNextCycle"
                                                            label="Next payment"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <DrawerFooter>
                                    <Button
                                        disabled={isCreatingCollection}
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}>
                                        {!isCreatingCollection ? (
                                            'Add'
                                        ) : (
                                            <CircularProgress size={20} />
                                        )}
                                    </Button>
                                </DrawerFooter>
                            </Grid>
                        );
                    }}
                </Formik>
            </DrawerContent>
        </Drawer>
    );
};

export default AddSubscriptionDrawer;
