import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CustomTextField from '../CustomTextField/CustomTextField';
import { useFormikContext } from 'formik';
import { formatISO, parseISO } from 'date-fns';

type SelectTimeProps = {
    name: string;
    label: string;
    disabledDate?: string;
    disabled?: boolean;
};

const SelectTime = ({ label, name, disabledDate, disabled }: SelectTimeProps) => {
    const { setFieldValue, values, errors, touched } = useFormikContext<any>();

    const [localDate, setLocalDate] = useState('');
    const [localTime, setLocalTime] = useState('');
    const [dateError, setDateError] = useState('');
    const [timeError, setTimeError] = useState('');

    const today = new Date().toISOString().split('T')[0];
    const parsedDisabledDate = disabledDate ? disabledDate.split('T')[0] : '';

    useEffect(() => {
        if (!values[name] && (localDate || localTime || dateError || timeError)) {
            setLocalDate('');
            setLocalTime('');
            setDateError('');
            setTimeError('');

            return;
        }

        const isoDate = values[name];
        if (isoDate) {
            const [date, time] = isoDate.split('T');
            setLocalDate(date);
            setLocalTime(time ? time.slice(0, 5) : '');
        }
    }, [values[name]]);

    useEffect(() => {
        setDateError('');
        setTimeError('');

        if (touched[name] && errors[name]) {
            if (!localDate) {
                setDateError(errors[name] as string);
            }
            if (!localTime) {
                setTimeError(errors[name] as string);
            } else if (localDate && localTime && errors[name]) {
                setDateError(errors[name] as string);
                setTimeError(errors[name] as string);
            }
        }
    }, [localDate, localTime, errors[name], touched[name]]);

    const handleDateChange = (date: string) => {
        setLocalDate(date);
        combineDateTimeAndSetFieldValue(date, localTime);
    };

    const handleTimeChange = (time: string) => {
        setLocalTime(time);
        combineDateTimeAndSetFieldValue(localDate, time);
    };

    const combineDateTimeAndSetFieldValue = (date: string, time: string) => {
        if (date && time) {
            const localDateTimeString = `${date}T${time}`;
            const localDateTime = parseISO(localDateTimeString);
            const combinedDateTimeISO = formatISO(localDateTime, { representation: 'complete' });
            setFieldValue(name, combinedDateTimeISO);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <CustomTextField
                    fullWidth
                    type="date"
                    label={`${label} date`}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                    value={localDate}
                    onChange={(e) => handleDateChange(e.target.value)}
                    error={Boolean(dateError)}
                    helperText={dateError || ''}
                    inputProps={{
                        min: parsedDisabledDate || today,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CustomTextField
                    fullWidth
                    type="time"
                    label={`${label} time`}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                    value={localTime}
                    onChange={(e) => handleTimeChange(e.target.value)}
                    error={Boolean(timeError)}
                    helperText={timeError || ''}
                />
            </Grid>
        </Grid>
    );
};

export default SelectTime;
