import { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';

type ModelActionsProps = {
    disabled: boolean;
    currentTab: 'active' | 'archived';
    onArchive: () => void;
    onUnarchive: () => void;
    onPublish: () => void;
    onUnpublish: () => void;
};

const ModelActions = ({
    disabled,
    currentTab,
    onArchive,
    onUnarchive,
    onPublish,
    onUnpublish,
}: ModelActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleArchiveUnarchive = () => {
        handleClose();
        if (currentTab === 'archived') {
            onUnarchive();
        } else {
            onArchive();
        }
    };

    const handlePublish = () => {
        handleClose();
        onPublish();
    };

    const handleUnpublish = () => {
        handleClose();
        onUnpublish();
    };

    return (
        <Box component="div">
            <Button variant="outlined" color="info" onClick={handleClick} disabled={disabled}>
                Actions
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleArchiveUnarchive}>
                    <Typography variant="p1">
                        {currentTab === 'archived' ? 'Unarchive' : 'Archive'}
                    </Typography>
                </MenuItem>
                {currentTab === 'active' ? (
                    <>
                        <MenuItem onClick={handlePublish}>
                            <Typography variant="p1">Publish</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleUnpublish}>
                            <Typography variant="p1">Unpublish</Typography>
                        </MenuItem>
                    </>
                ) : null}
            </Menu>
        </Box>
    );
};

export default ModelActions;
