import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: 'This field is required.',
        notType: function notType(_ref) {
            switch (_ref.type) {
                case 'number':
                    return 'This field is required.';
                default:
                    return 'This field is required.';
            }
        },
    },
    array: {
        min: 'There is min. ' + '${min} ' + 'item required.',
    },
    string: {
        email: 'Provided e-mail is incorrect.',
        max: 'The maximum number of characters is ' + '${max}.',
    },
    number: {
        min: 'Min value is ' + '${min}.',
    },
});

export default yup;
