import { useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { Collection } from '../../types/collection';
import { deleteCollection, getCollections } from '../../api/collection';
import { COLLECTIONS_TABLE_COLUMNS } from '../../const/collectionsTableColumns';
import CollectionCreator from './CollectionCreator';
import { BaseFilters } from '../../types/table';
import { BASE_PAGE_SIZE_OPTIONS, calculateRowHeightAndPageSize } from '../../helpers/datagrid';
import { APIError } from '../../axios/axiosVeerlInstance';

import { ReactComponent as EditIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as ArrowBackOutlinedIcon } from '../../assets/icons/arrow-back-outlined.svg';

const CollectionsView = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const setToastState = useSetAtom(toastAtom);

    const rowProperties = calculateRowHeightAndPageSize(210);
    const savedPageSize = localStorage.getItem('collections.pageSize');

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { collectionsFilters } = location.state || {};
    const { referrer } = location.state || [];

    const [filters, setFilters] = useState<BaseFilters>(
        collectionsFilters || {
            search: '',
            sort: {
                field: 'dateUpdate',
                order: 'desc',
            },
            pageSize:
                savedPageSize &&
                +savedPageSize &&
                BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize).includes(+savedPageSize)
                    ? +savedPageSize
                    : rowProperties.pageSize,
            page: 1,
        }
    );

    const [isCreatorOpen, setIsCreatorOpen] = useState<boolean>(false);
    const [collectionToDelete, setCollectionToDelete] = useState<Collection | null>(null);
    const [editingCollection, setEditingCollection] = useState<Collection | null>(null);

    const { data: collectionsResponse, isFetching } = useQuery(['collections', filters], () =>
        getCollections(filters)
    );

    const collections = collectionsResponse?.collections || [];
    const newReferrer = referrer?.length ? [...referrer, '/collections'] : ['/collections'];

    const deleteMutation = useMutation(deleteCollection, {
        onSuccess: () => {
            setToastState({
                message: 'Collection deleted successfully!',
                severity: 'success',
            });
            setCollectionToDelete(null);
            queryClient.invalidateQueries('collections');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const onDeleteModel = () => {
        if (!collectionToDelete) return;

        deleteMutation.mutate(collectionToDelete.id);
    };

    const onCollectionCreatorClose = () => {
        setIsCreatorOpen(false);
        setEditingCollection(null);
    };

    const rowCountRef = useRef(collectionsResponse?.totalResults || 0);

    const rowCount = useMemo(() => {
        if (collectionsResponse?.totalResults !== undefined) {
            rowCountRef.current = collectionsResponse.totalResults;
        }
        return rowCountRef.current;
    }, [collectionsResponse?.totalResults]);

    return (
        <>
            <CollectionCreator
                editingCollection={editingCollection}
                isOpen={isCreatorOpen || editingCollection !== null}
                onClose={onCollectionCreatorClose}
            />
            <Box component="div" flexDirection="column">
                <Grid
                    item
                    xs={12}
                    sx={{
                        padding: '24px 20px',
                    }}>
                    <Grid container gap={isSmallScreen ? '16px' : 0}>
                        <Grid item xs={isSmallScreen ? 12 : 4}>
                            <CustomTextField
                                fullWidth
                                name="search"
                                label="Search"
                                size="small"
                                onChange={(e: any) =>
                                    setFilters({
                                        ...filters,
                                        search: e.target.value,
                                        page: 1,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 8}>
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                <Button
                                    onClick={() => setIsCreatorOpen(true)}
                                    variant="contained"
                                    color="primary"
                                    endIcon={!isMediumScreen ? <PlusIcon /> : null}>
                                    {!isMediumScreen ? 'Add Collection' : <PlusIcon />}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    gap="16px"
                    sx={{
                        padding: '16px 20px',
                    }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: 'relative',
                        }}>
                        <CustomDataGrid
                            height="calc(100vh - 210px)"
                            disableColumnFilter
                            tableName="collections"
                            rows={collections}
                            onRowClick={(row: any) =>
                                navigate('/models', {
                                    state: {
                                        collection: row.row,
                                        collectionsFilters: filters,
                                        referrer: newReferrer,
                                    },
                                })
                            }
                            columns={COLLECTIONS_TABLE_COLUMNS({
                                onEdit: (row) => setEditingCollection(row),
                                onDelete: (row) => setCollectionToDelete(row),
                                onOpenModels: (row) =>
                                    navigate('/models', {
                                        state: {
                                            collection: row,
                                            collectionsFilters: filters,
                                            referrer: newReferrer,
                                        },
                                    }),
                            })}
                            rowCount={rowCount}
                            rowProperties={rowProperties}
                            filters={filters}
                            onFiltersChange={(filters) => {
                                setFilters({
                                    ...filters,
                                    sort: {
                                        field: filters.sort ? filters.sort.field : 'dateUpdate',
                                        order: filters.sort ? filters.sort.order : 'desc',
                                    },
                                });
                            }}
                            loading={isFetching}
                            localeText={{
                                noRowsLabel:
                                    filters.search !== ''
                                        ? 'No collections found.'
                                        : 'Create your first collection.',
                                noResultsOverlayLabel:
                                    filters.search !== ''
                                        ? 'No collections found.'
                                        : 'Create your first collection.',
                            }}
                        />
                    </Grid>
                </Grid>
                <CustomDialog
                    open={!!collectionToDelete}
                    onClose={() => setCollectionToDelete(null)}
                    onConfirm={onDeleteModel}
                    title="Delete model"
                    content={
                        <Typography variant="p1" textAlign="center">
                            Are you sure that you want to delete collection{' '}
                            <Typography variant="p1SemiBold">{collectionToDelete?.name}</Typography>
                            ? From this action collection will be permanently deleted.
                        </Typography>
                    }
                />
            </Box>
        </>
    );
};

export default CollectionsView;
