import { Collection } from './collection';
import { BaseFilters } from './table';

export type Model = {
    id: string;
    archived?: boolean;
    canBeDeleted?: boolean;
    name: string;
    description: string[];
    pose: string[];
    type: ModelType;
    tier: Tier;
    likes: {
        booster: number;
        count?: number;
    };
    stock?: number | string | null;
    stockAvailable?: number;
    rarity: ModelRarity;
    sortOrder: number;
    collections: Collection[];
    isPublic: boolean;
    isFreeForGuests: boolean;
    isFreeForUsers: boolean;
    price?: number | null;
    discount?: ModelDiscount;
    datePublication: string;
    dimensions: ModelDimensions;
    attributes: ModelAttributes;
    highRes: Model3D;
    lowRes?: Model3D;
    background: string;
    thumbnail: string;
    video: string;
    videoAndroid: string;
    videoIOS: string;
    androidModelSize?: number;
    iosModelSize?: number;
};

export type ModelFormData = Model & {
    collectionsIds: string[];
};

export type Model3D = {
    model: string;
    assets?: string[];
    assetsErrors?: string[];
};

export type ModelDiscount = {
    active: boolean;
    value?: number | null;
    dateStart?: string;
    dateEnd?: string;
};

export const INITIAL_MODEL_DISCOUNT_DATA: ModelDiscount = {
    active: false,
    value: null,
    dateStart: '',
    dateEnd: '',
};

export const INITAL_MODEL_DATA: ModelFormData = {
    id: '',
    name: '',
    description: [],
    pose: [],
    type: 'static',
    tier: 1,
    isPublic: false,
    isFreeForUsers: false,
    isFreeForGuests: false,
    price: null,
    discount: INITIAL_MODEL_DISCOUNT_DATA,
    datePublication: '',
    collectionsIds: [],
    collections: [],
    sortOrder: 100,
    rarity: 'mystic',
    likes: {
        booster: 0,
    },
    dimensions: {
        height: null,
        breast: null,
        waist: null,
        hips: null,
    },
    attributes: {
        race: '',
        hair: '',
        breast: '',
    },
    highRes: {
        model: '',
        assets: [],
        assetsErrors: [],
    },
    lowRes: {
        model: '',
        assets: [],
        assetsErrors: [],
    },
    thumbnail: '',
    video: '',
    videoAndroid: '',
    videoIOS: '',
    background: '',
};

export type ModelRarity = 'mystic' | 'temptation' | 'secret' | 'divine';

export type ModelType = 'static' | 'animated' | 'video';

export type ModelDimensions = {
    height: number | null | string;
    breast: number | null | string;
    waist: number | null | string;
    hips: number | null | string;
};

export type ModelAttributes = {
    race: string | null;
    hair: string | null;
    breast: string | null;
};

export type Tier = 1 | 2 | 3;

export const TIER_OPTIONS = [
    {
        value: 1,
        label: 'Tier 1',
    },
    {
        value: 2,
        label: 'Tier 2',
    },
    {
        value: 3,
        label: 'Tier 3',
    },
];

export type ModelsFilters = BaseFilters & {
    search: string;
    isPublic?: string;
    archived: string;
    pose?: string[];
    type?: ModelType;
    isPaid?: boolean;
    isFreeForGuests?: boolean;
    isFreeForUsers?: boolean;
    tier?: Tier[];
    collection: {
        id: string[];
    };
    datePublication?: {
        from: string | null;
        to: string | null;
    };
    dimensions?: {
        height?: {
            from: number | null;
            to: number | null;
        };
        breast?: {
            from: number | null;
            to: number | null;
        };
        waist?: {
            from: number | null;
            to: number | null;
        };
        hips?: {
            from: number | null;
            to: number | null;
        };
    };
    attributes?: {
        race?: string[];
        hair?: string[];
        breast?: string[];
    };
};

export type ArchiveModelsMutation = {
    modelIds: string[];
    archive: boolean;
};

export type PublishModelsMutation = {
    modelIds: string[];
    publish: boolean;
};

export const MODELS_FILTERS_INITIAL: ModelsFilters = {
    search: '',
    pageSize: 10,
    page: 1,
    archived: 'false',
    collection: {
        id: [],
    },
    attributes: {
        race: [],
        hair: [],
        breast: [],
    },
};
