import Tooltip from '@mui/material/Tooltip';
import Chip, { ChipProps } from '@mui/material/Chip';

type CustomChipProps = ChipProps & {
    disabletooltip?: boolean;
    tooltip?: string;
};

const CustomChip = (props: CustomChipProps) => {
    return (
        <>
            {!props.disabletooltip ? (
                <Tooltip title={props.tooltip || props.label}>
                    <Chip {...props} />
                </Tooltip>
            ) : (
                <Chip {...props} />
            )}
        </>
    );
};

export default CustomChip;
